const state = {
  toggleSideMenu: false,
  sideMenuMinimize: null,
  transitionEffect: 'slide-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
  itemView: 'list-view',
  exchange: {
    banka_kodu: 'TRY',
    kod: '₺',
    alis: 1,
    satis: 1,
  },
};

const getters = {
  getToggleSideMenu: (state) => state.toggleSideMenu,
  getToggleSideMenuMinimize: (state) => state.sideMenuMinimize,
  getTransitionEffect: (state) => state.transitionEffect,
  getItemView: (state) => state.itemView,
  getExchange: (state) => state.exchange,
};

const mutations = {
  TOGGLE_SIDE_MENU(state, val) {
    state.toggleSideMenu = val;
  },
  TOGGLE_SIDE_MENU_MINIMIZE(state, val) {
    state.sideMenuMinimize = val;
  },
  TOGGLE_ITEM_VIEW(state, val) {
    state.itemView = val;
  },
  setExchange(state, payload) {
    state.exchange = payload;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
