import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  kategoriler: [],
  tum_kategoriler: [],
};
const getters = {
  getKategoriler: (state) => state.kategoriler,
  getTumKategoriler: (state) => state.tum_kategoriler,
};
const mutations = {
  SET_KATEGORILER(state, payload) {
    state.tum_kategoriler = payload;
    let kategoriler = [];
    payload.forEach((el) => {
      if (el.kategori_k_no == null) kategoriler.push({ ...el, children: [] });
    });

    payload.forEach((el, i) => {
      if (el.kategori_k_no != null) {
        let index = kategoriler.findIndex((x) => x.k_no == el.kategori_k_no);
        if (index != -1) {
          kategoriler[index].children.push(el);
        }
      }
    });

    state.kategoriler = kategoriler;
  },
  SET_KATEGORILER_PUSH(state, payload) {
    state.kategoriler.push(payload);
  },
  SET_KATEGORILER_UPDATE(state, payload) {
    let index = state.kategoriler.findIndex((x) => x.k_no == payload.k_no);
    state.kategoriler[index] = payload;
  },
  SET_KATEGORILER_DELETE(state, k_no) {
    let altindex = state.kategoriler.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.kategoriler.splice(altindex, 1);
    }
    let index = state.kategoriler.findIndex((x) => x.k_no == k_no);
    state.kategoriler.splice(index, 1);
  },
};
const actions = {
  async kategoriListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/kategori-listele', payload).then((res) => {
      commit('SET_KATEGORILER', res.data.data);
      return res;
    });
  },
  async kategoriEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-ekle', data).then((res) => {
      commit('SET_KATEGORILER_PUSH', res.data.data);
      return res;
    });
  },
  async kategoriGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-guncelle', data).then((res) => {
      commit('SET_KATEGORILER', res.data.data);
      return res;
    });
  },
  async kategoriSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async kategoriSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/kategori-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_KATEGORILER_DELETE', k_no);
      }
      return res;
    });
  },
  async kategoriGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/tanimlamalar/kategori-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_KATEGORILER_UPDATE', res.data.data);
        return res;
      });
  },
  async kategoriGorselSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-gorsel-sira-guncelle', data).then((res) => {
      commit('SET_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },
  async kategoriGorselKapakGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-gorsel-kapak-guncelle', data).then((res) => {
      commit('SET_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },

  async kategoriGorselBannerGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-gorsel-banner-guncelle', data).then((res) => {
      commit('SET_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },
  async kategoriGorselSil({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kategori-gorsel-sil', data).then((res) => {
      commit('SET_KATEGORILER_UPDATE', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
