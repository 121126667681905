import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  odeme_Bildirim: [],
};
const getters = {
  getRezervasyonOdemeBildirim: (state) => state.odeme_Bildirim,
};
const mutations = {
  SET_ODEMEBILDIRIM(state, payload) {
    state.odeme_Bildirim = payload;
  },
  SET_ODEMEBILDIRIM_PUSH(state, payload) {
    state.odeme_Bildirim.push(payload);
  },
  SET_ODEMEBILDIRIM_UPDATE(state, payload) {
    let index = state.odeme_Bildirim.findIndex((x) => x.k_no == payload.k_no);
    state.odeme_Bildirim[index] = payload;
  },
  SET_ODEMEBILDIRIM_ONAY_UPDATE(state, payload) {
    let index = state.odeme_Bildirim.findIndex((x) => x.k_no == payload.k_no);
    if (index != -1) state.odeme_Bildirim[index].statu = true;
  },
  SET_ODEMEBILDIRIM_DELETE(state, k_no) {
    let index = state.odeme_Bildirim.findIndex((x) => x.k_no == k_no);
    state.odeme_Bildirim.splice(index, 1);
  },
};
const actions = {
  async rezervasyonOdemeBildirimListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/rezervasyonlar/rezervasyon-odeme-bildirim-listele', payload).then((res) => {
      commit('SET_ODEMEBILDIRIM', res.data.data);
      return res;
    });
  },
  async rezervasyonOdemeBildirimEkle({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-odeme-bildirim-ekle', data).then((res) => {
      commit('SET_ODEMEBILDIRIM_PUSH', res.data.data);
      return res;
    });
  },
  async rezervasyonOdemeBildirimGuncelle({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-odeme-bildirim-guncelle', data).then((res) => {
      commit('SET_ODEMEBILDIRIM_UPDATE', res.data.data);
      return res;
    });
  },
  async rezervasyonOdemeBildirimSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/rezervasyonlar/rezervasyon-odeme-bildirim-sil', payload).then((res) => {
      commit('SET_ODEMEBILDIRIM_DELETE', k_no);
      return res;
    });
  },
  async rezervasyonOdemeBildirimOnayla({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-odeme-bildirim-onayla', data).then((res) => {
      if (res.data.success) {
        commit('SET_ODEMEBILDIRIM_ONAY_UPDATE', data);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
