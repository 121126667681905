import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  rezervasyonlar: [],
};
const getters = {
  getRezervasyonlar: (state) => state.rezervasyonlar,
};
const mutations = {
  SET_REZERVASYONLAR(state, payload) {
    state.rezervasyonlar = payload;
  },
  SET_REZERVASYONLAR_PUSH(state, payload) {
    state.rezervasyonlar.push(payload);
  },
  SET_REZERVASYONLAR_UPDATE(state, payload) {
    let index = state.rezervasyonlar.findIndex((x) => x.k_no == payload.k_no);
    state.rezervasyonlar[index] = payload;
  },
  SET_REZERVASYONLAR_DELETE(state, k_no) {
    let index = state.rezervasyonlar.findIndex((x) => x.k_no == k_no);
    state.rezervasyonlar.splice(index, 1);
  },
};
const actions = {
  async rezervasyonListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/rezervasyonlar/rezervasyon-listele', payload).then((res) => {
      commit('SET_REZERVASYONLAR', res.data.data);
      return res;
    });
  },
  async rezervasyonEkle({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-ekle', data).then((res) => {
      commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },
  async rezervasyonGuncelle({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-guncelle', data).then((res) => {
      commit('SET_REZERVASYONLAR_UPDATE', res.data.data);
      return res;
    });
  },

  async rezervasyonSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/rezervasyonlar/rezervasyon-sil', payload).then((res) => {
      commit('SET_REZERVASYONLAR_DELETE', k_no);
      return res;
    });
  },
  async rezervasyonTopluSil({ commit }, payload) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-toplu-sil', payload).then((res) => {
      if (res.data.success == true) {
        payload.k_no.forEach((element) => {
          commit('SET_REZERVASYONLAR_DELETE', element.k_no);
        });
      }
      return res;
    });
  },
  async rezervasyonKopyala({ commit }, payload) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-kopyala', payload).then((res) => {
      return res;
    });
  },
  async rezervasyonTopluGuncelle({ commit }, payload) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-toplu-guncelle', payload).then((res) => {
      return res;
    });
  },
  async rezervasyonFindOne({ }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/rezervasyonlar/rezervasyon-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },

  async rezervasyonMailGonder({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-mail-gonder', data).then((res) => {
      return res;
    });
  },

  async rezervasyonWhatsappBiletGonder({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-whatsapp-bilet-gonder', data).then((res) => {
      return res;
    });
  },

  async rezervasyonTamamlamaMailGonder({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-tamamlama-mail-gonder', data).then((res) => {
      return res;
    });
  },

  async rezervasyonTahsilatEkle({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-tahsilat-ekle', data).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },

  async rezervasyonTahsilatSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/rezervasyonlar/rezervasyon-tahsilat-sil', payload).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },

  async onTalepListele({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-on-talep-listele', data).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },

  async ozelTalepListele({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-ozel-talep-listele', data).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },
  async iptalTalepListele({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-iptal-talep-listele', data).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },

  async iptalTalepStatuGuncelle({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-iptal-talep-statu-guncelle', data).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },

  async iptalTalepSil({ commit }, data) {
    return await axiosIns.post('/rezervasyonlar/rezervasyon-iptal-talep-sil', data).then((res) => {
      // commit('SET_REZERVASYONLAR_PUSH', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
