import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  kuponlar: [],
};
const getters = {
  getKuponlar: (state) => state.kuponlar,
};
const mutations = {
  SET_KUPON(state, payload) {
    state.kuponlar = payload;
  },
  SET_KUPON_PUSH(state, payload) {
    state.kuponlar.push(payload);
  },
  SET_KUPON_UPDATE(state, payload) {
    let index = state.kuponlar.findIndex((x) => x.k_no == payload.k_no);
    state.kuponlar[index] = payload;
  },
  SET_KUPON_DELETE(state, k_no) {
    let altindex = state.kuponlar.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.kuponlar.splice(altindex, 1);
    }
    let index = state.kuponlar.findIndex((x) => x.k_no == k_no);
    state.kuponlar.splice(index, 1);
  },
};
const actions = {
  async kuponListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/kupon-listele', payload).then((res) => {
      commit('SET_KUPON', res.data.data);
      return res;
    });
  },
  async kuponEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kupon-ekle', data).then((res) => {
      commit('SET_KUPON_PUSH', res.data.data);
      return res;
    });
  },
  async kuponGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kupon-guncelle', data).then((res) => {
      commit('SET_KUPON_UPDATE', res.data.data);
      return res;
    });
  },
  async kuponSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/kupon-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async kuponSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/kupon-sil', payload).then((res) => {
      commit('SET_KUPON_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
