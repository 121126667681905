import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  slider: [],
};
const getters = {
  getSlide: (state) => {
    return state.slider;
  },
};
const mutations = {
  SET_SLIDE(state, payload) {
    state.slider = payload;
  },
  SET_SLIDE_PUSH(state, payload) {
    state.slider.push(payload);
  },
  SET_SLIDE_UPDATE(state, payload) {
    let index = state.slider.findIndex((x) => x.k_no == payload.k_no);
    state.slider[index] = payload;
  },
  SET_SLIDE_DELETE(state, k_no) {
    let index = state.slider.findIndex((x) => x.k_no == k_no);
    state.slider.splice(index, 1);
  },
};
const actions = {
  async slideListele({ commit }, data) {
    return await axiosIns.post('/magaza/slide-listele', data).then((res) => {
      commit('SET_SLIDE', res.data.data);
      return res;
    });
  },
  async slideEkle({ commit }, data) {
    return await axiosIns
      .post('/magaza/slide-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          const data = res.data.data;
          data.forEach((element) => {
            commit('SET_SLIDE_PUSH', element);
          });
          return res;
        }
      });
  },
  async slideGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/slide-guncelle', payload).then((res) => {
      commit('SET_SLIDE_UPDATE', res.data.data);
      return res;
    });
  },
  async slideSiraGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/slide-sira-guncelle', payload).then((res) => {
      commit('SET_SLIDE', res.data.data);
      return res;
    });
  },
  async slideSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/magaza/slide-sil', payload).then((res) => {
      commit('SET_SLIDE_DELETE', k_no);
      return res;
    });
  },
  async slideFindOne({}, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/magaza/slide-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
