export default [
  {
    path: '/kullanicilar',
    name: 'kullanicilar',
    redirect: '/kullanici/listele',
    component: () => import(/* webpackChunkName: "kullanicilar" */ '@/views/kullanicilar/index.vue'),
    children: [
      {
        path: '/kullanici/listele',
        name: 'kullanici-listele',
        component: () => import(/* webpackChunkName: "kullanici-listele" */ '@/views/kullanicilar/kullanicilar.vue'),
        meta: { title: 'Kullanıcı Liste', noCache: true }
      },
      {
        path: '/kullanici/yetkiler',
        name: 'kullanici-yetkiler',
        component: () => import(/* webpackChunkName: "kullanici-listele" */ '@/views/kullanicilar/yetkilendirme.vue'),
        meta: { title: 'Kullanıcı Yetki', noCache: true }
      },
    ],
  },
];
