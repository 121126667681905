export default [
  {
    path: '/tanimlama/kategoriler',
    name: 'kategoriler',
    component: () => import(/* webpackChunkName: "kategoriler" */ '@/views/tanimlamalar/kategoriler/index.vue'),
    meta: { title: 'Kategoriler', noCache: true }
  },
  {
    path: '/tanimlama/bolgeler',
    name: 'bolgeler',
    component: () => import(/* webpackChunkName: "bolgeler" */ '@/views/tanimlamalar/bolgeler/index.vue'),
    meta: { title: 'Bölgeler', noCache: true }
  },
  {
    path: '/tanimlama/hoteller',
    name: 'hoteller',
    redirect: '/tanimlama/hotel/listele',
    component: () => import(/* webpackChunkName: "hoteller" */ '@/views/tanimlamalar/hoteller/index.vue'),
    children: [
      {
        path: '/tanimlama/hotel/listele',
        name: 'hotel-listele',
        component: () => import(/* webpackChunkName: "hotel-listele" */ '@/views/tanimlamalar/hoteller/listele.vue'),
        meta: { title: 'Hoteller', noCache: true }
      },
    ],
  },
  {
    path: '/tanimlama/transfer',
    name: 'transfer-islemleri',
    redirect: '/tanimlama/transfer/tarife-bolgeleri',
    component: () => import(/* webpackChunkName: "ayarlar" */ '@/views/tanimlamalar/transfer/index.vue'),
    children: [
      {
        path: '/tanimlama/transfer/tarife-bolgeleri',
        name: 'tarife-bolgeleri',
        component: () => import(/* webpackChunkName: "bolgeler" */ '@/views/tanimlamalar/transfer/tarifeBolgeleri/index.vue'),
        meta: { title: 'Tarife Bölgeleri', noCache: true }
      },
      {
        path: '/tanimlama/transfer/araclar',
        name: 'araclar',
        component: () => import(/* webpackChunkName: "bolgeler" */ '@/views/tanimlamalar/transfer/araclar/index.vue'),
        meta: { title: 'Araçlar', noCache: true }
      },
      {
        path: '/tanimlama/transfer/ayarlar',
        name: 'transfer-ayarlar',
        component: () => import(/* webpackChunkName: "bolgeler" */ '@/views/tanimlamalar/transfer/ayarlar/index.vue'),
        meta: { title: 'Transfer Ayar', noCache: true }
      },
      // {
      //   path: '/ayarlar/seo',
      //   name: 'seo',
      //   component: () => import(/* webpackChunkName: "seo" */ '@/views/ayarlar/seo/index.vue'),
      // },
    ],
  },
  // {
  //   path: '/tanimlama/transfer-bolgeleri',
  //   name: 'transfer-bolgeleri',
  //   component: () => import(/* webpackChunkName: "transfer-bolgeleri" */ '@/views/tanimlamalar/transferBolgeleri/index.vue'),
  // },
  // {
  //   path: '/tanimlama/transfer-araclar',
  //   name: 'transfer-araclar',
  //   component: () => import(/* webpackChunkName: "transfer-araclar" */ '@/views/tanimlamalar/transferAraclar/index.vue'),
  // },
  {
    path: '/tanimlama/urun-ozel-alan',
    name: 'urun-ozel-alan',
    component: () => import(/* webpackChunkName: "urun-ozel-alan" */ '@/views/tanimlamalar/urunOzelAlanlar/index.vue'),
    meta: { title: 'Ürün Özel Alanlar', noCache: true }
  },
  {
    path: '/tanimlama/ekstra-hizmetler',
    name: 'ekstra-hizmetler',
    component: () => import(/* webpackChunkName: "ekstra-hizmetler" */ '@/views/tanimlamalar/ekstraHizmetler/index.vue'),
    meta: { title: 'Ekstra Hizmetler', noCache: true }
  },
  {
    path: '/tanimlama/varyant-grup',
    name: 'varyant-gruplari',
    component: () => import(/* webpackChunkName: "varyant-gruplari" */ '@/views/tanimlamalar/variantGrublari/index.vue'),
    meta: { title: 'Variant Grubları', noCache: true }
  },
  {
    path: '/tanimlama/rezervasyon-durumlari',
    name: 'rezervasyon-durumlari',
    component: () =>
      import(/* webpackChunkName: "rezervasyon-durumlari" */ '@/views/tanimlamalar/rezervasyonDurumlari/index.vue'),
    meta: { title: 'Rezervasyon Durumlar', noCache: true }
  },
  {
    path: '/tanimlama/uye-gruplari',
    name: 'uye-gruplari',
    component: () => import(/* webpackChunkName: "uye-gruplari" */ '@/views/tanimlamalar/uyeGruplari/index.vue'),
    meta: { title: 'Üye Grupları', noCache: true }
  },
  {
    path: '/tanimlama/kampanyalar',
    name: 'kampanyalar',
    component: () => import(/* webpackChunkName: "kampanyalar" */ '@/views/tanimlamalar/kampanyalar/index.vue'),
    meta: { title: 'Kampanyalar', noCache: true },
    children: [
      {
        path: '/tanimlama/kampanyalar/ekle',
        name: 'kampanya-ekle',
        component: () =>
          import(/* webpackChunkName: "kampanya-ekle" */ '@/views/tanimlamalar/kampanyalar/component/KampanyaEkle.vue'),
        meta: { title: 'Kampanya Ekle', noCache: true },
      },
      {
        path: '/tanimlama/kampanyalar/guncelle/:k_no?',
        name: 'kampanya-guncelle',
        component: () =>
          import(/* webpackChunkName: "kampanya-guncelle" */ '@/views/tanimlamalar/kampanyalar/component/KampanyaEkle.vue'),
        meta: { title: 'Kampanya Güncelle', noCache: false },
      },
      {
        path: '/tanimlama/kampanyalar/ayar',
        name: 'kampanya-ayar',
        component: () =>
          import(/* webpackChunkName: "kampanya-ayar" */ '@/views/tanimlamalar/kampanyalar/component/KampanyaAyarlar.vue'),
        meta: { title: 'Kampanya Ayar', noCache: true },
      },
    ],
  },
  {
    path: '/tanimlama/kuponlar',
    name: 'kuponlar',
    component: () => import(/* webpackChunkName: "kuponlar" */ '@/views/tanimlamalar/kuponlar/index.vue'),
    meta: { title: 'Kuponlar', noCache: true },
  },
];
