import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  sor_sat_rezervasyon: {
    k_no: null,
    uye: null,
    acente_k_no: null,
    lang: 'tr',
    sepet_urunler: [],
    promosyon_kodu: null,
    satis_yontemi: 'direk',
    rezervasyon_satis_durum: 'sor_sat',
    misafir: {
      ad: null,
      soyad: null,
      dogum_tarihi: null,
      tc_pasaport: null,
      adres: null,
      telefon: null,
      e_mail: null,
    },
    rezervasyon_notu: null,
    hotel_bilgi: {
      hotel_adi: null,
      oda_no: null,
      sehir: null,
      ilce: null,
    },
    kdv_turu: 'dahil',
    kdv_oran: 0,
    sabit_kur: 'TRY',
    sabit_kur_fiyat: 0,
    secilen_kur: null,
    secilen_kur_fiyat: 0,
    rezervasyon_toplam: {
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      sepet_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
      tahsilat_toplam: 0,
    },
    kur_rezervasyon_toplam: {
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      sepet_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
      tahsilat_toplam: 0,
    },
    gun_fiyatlar: [],
    odeme_yontemi: 'kredi_karti',
    odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
    odeme_tipi: 'on_odeme',
    tahsilat_durum: 0,
    rezervasyon_durum: null,
    banka_k_no: null,
    kart_bilgileri: {
      kart_ad_soyad: null,
      kart_numarasi: null,
      kart_ay_yil: null,
      kart_ccv: null,
      kart_3d_guvenlik: false,
      kart_taksit: 1,
    },
    mesafeli_satis_sozlesmesi: true,
    kiralama_sozlesmesi: true,
  },
  direk_rezervasyon: {
    k_no: null,
    uye: null,
    acente_k_no: null,
    lang: 'tr',
    sepet_urunler: [],
    promosyon_kodu: null,
    satis_yontemi: 'direk',
    rezervasyon_satis_durum: 'direk',
    misafir: {
      ad: null,
      soyad: null,
      dogum_tarihi: null,
      tc_pasaport: null,
      adres: null,
      telefon: null,
      e_mail: null,
    },
    rezervasyon_notu: null,
    hotel_bilgi: {
      hotel_adi: null,
      oda_no: null,
      sehir: null,
      ilce: null,
    },
    kdv_turu: 'dahil',
    kdv_oran: 0,
    sabit_kur: 'TRY',
    sabit_kur_fiyat: 0,
    secilen_kur: null,
    secilen_kur_fiyat: 0,
    rezervasyon_toplam: {
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      sepet_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
      tahsilat_toplam: 0,
    },
    kur_rezervasyon_toplam: {
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      sepet_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
      tahsilat_toplam: 0,
    },
    gun_fiyatlar: [],
    odeme_yontemi: 'kredi_karti',
    odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
    odeme_tipi: 'on_odeme',
    tahsilat_durum: 0,
    rezervasyon_durum: null,
    banka_k_no: null,
    kart_bilgileri: {
      kart_ad_soyad: null,
      kart_numarasi: null,
      kart_ay_yil: null,
      kart_ccv: null,
      kart_3d_guvenlik: false,
      kart_taksit: 1,
    },
    mesafeli_satis_sozlesmesi: true,
    kiralama_sozlesmesi: true,
  },
  duzenle_rezervasyon: {
    k_no: null,
    uye: null,
    acente_k_no: null,
    lang: 'tr',
    sepet_urunler: [],
    promosyon_kodu: null,
    satis_yontemi: 'direk',
    rezervasyon_satis_durum: 'direk',
    misafir: {
      ad: null,
      soyad: null,
      dogum_tarihi: null,
      tc_pasaport: null,
      adres: null,
      telefon: null,
      e_mail: null,
    },
    rezervasyon_notu: null,
    hotel_bilgi: {
      hotel_adi: null,
      oda_no: null,
      sehir: null,
      ilce: null,
    },
    kdv_turu: 'dahil',
    kdv_oran: 0,
    sabit_kur: 'TRY',
    sabit_kur_fiyat: 0,
    secilen_kur: null,
    secilen_kur_fiyat: 0,
    rezervasyon_toplam: {
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      iskonto_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
      tahsilat_toplam: 0,
    },
    kur_rezervasyon_toplam: {
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      iskonto_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
      tahsilat_toplam: 0,
    },
    gun_fiyatlar: [],
    odeme_yontemi: 'kredi_karti',
    odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
    odeme_tipi: 'on_odeme',
    tahsilat_durum: 0,
    banka_k_no: null,
    kart_bilgileri: {
      kart_ad_soyad: null,
      kart_numarasi: null,
      kart_ay_yil: null,
      kart_ccv: null,
      kart_3d_guvenlik: false,
      kart_taksit: 1,
    },
    mesafeli_satis_sozlesmesi: true,
    kiralama_sozlesmesi: true,
  },
};
const getters = {
  getSorSatRezervasyon: (state) => state.sor_sat_rezervasyon,
  getDirekRezervasyon: (state) => state.direk_rezervasyon,
  getDuzenleRezervasyon: (state) => state.duzenle_rezervasyon,
};
const mutations = {
  SET_SOR_SAT_REZERVASYON(state, payload) {
    state.sor_sat_rezervasyon = payload;
  },

  SET_SEPET_SORSAT_URUN_PUSH(state, payload) {
    state.sor_sat_rezervasyon.sepet_urunler.push(payload);
  },

  SET_SEPET_SORSAT_REZERVASYON_RESET(state) {
    state.sor_sat_rezervasyon = {
      k_no: null,
      uye: null,
      acente_k_no: null,
      lang: 'tr',
      sepet_urunler: [],
      promosyon_kodu: null,
      satis_yontemi: 'direk',
      rezervasyon_satis_durum: 'sor_sat',
      misafir: {
        ad: null,
        soyad: null,
        dogum_tarihi: null,
        tc_pasaport: null,
        adres: null,
        telefon: null,
        e_mail: null,
      },
      diger_misafir: [],
      rezervasyon_notu: null,
      hotel_bilgi: {
        hotel_adi: null,
        oda_no: null,
        sehir: null,
        ilce: null,
      },
      kdv_turu: 'dahil',
      kdv_oran: 0,
      sabit_kur: 'TRY',
      sabit_kur_fiyat: 0,
      secilen_kur: null,
      secilen_kur_fiyat: 0,
      rezervasyon_toplam: {
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      kur_rezervasyon_toplam: {
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      gun_fiyatlar: [],
      odeme_yontemi: 'kredi_karti',
      odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
      odeme_tipi: 'on_odeme',
      tahsilat_durum: 0,
      banka_k_no: null,
      kart_bilgileri: {
        kart_ad_soyad: null,
        kart_numarasi: null,
        kart_ay_yil: null,
        kart_ccv: null,
        kart_3d_guvenlik: false,
        kart_taksit: 1,
      },
      mesafeli_satis_sozlesmesi: true,
      kiralama_sozlesmesi: true,
    };
  },

  SET_SEPET_SORSAT_URUN_DELETE(state, index) {
    state.direk_rezervasyon.sepet_urunler.splice(index, 1);
  },

  SET_DIREK_REZERVASYON(state, payload) {
    state.direk_rezervasyon = payload;
  },

  SET_SEPET_DIREK_REZERVASYON_RESET(state) {
    state.direk_rezervasyon = {
      k_no: null,
      uye: null,
      acente_k_no: null,
      lang: 'tr',
      urun_tipi: null,
      sepet_urunler: [],
      promosyon_kodu: null,
      satis_yontemi: 'direk',
      rezervasyon_satis_durum: 'direk',
      misafir: {
        ad: null,
        soyad: null,
        dogum_tarihi: null,
        tc_pasaport: null,
        adres: null,
        telefon: null,
        e_mail: null,
      },
      rezervasyon_notu: null,
      hotel_bilgi: {
        hotel_adi: null,
        oda_no: null,
        sehir: null,
        ilce: null,
      },
      kdv_turu: 'dahil',
      kdv_oran: 0,
      sabit_kur: 'TRY',
      sabit_kur_fiyat: 0,
      secilen_kur: null,
      secilen_kur_fiyat: 0,
      rezervasyon_toplam: {
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        iskonto_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      kur_rezervasyon_toplam: {
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        iskonto_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      gun_fiyatlar: [],

      odeme_yontemi: 'kredi_karti',
      odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
      odeme_tipi: 'on_odeme',
      tahsilat_durum: 0,
      banka_k_no: null,
      kart_bilgileri: {
        kart_ad_soyad: null,
        kart_numarasi: null,
        kart_ay_yil: null,
        kart_ccv: null,
        kart_3d_guvenlik: false,
        kart_taksit: 1,
      },
      mesafeli_satis_sozlesmesi: true,
      kiralama_sozlesmesi: true,
    };
  },

  SET_SEPET_DIREK_URUN_PUSH(state, payload) {
    state.direk_rezervasyon.sepet_urunler.push(payload);
  },

  SET_SEPET_DIREK_URUN_DELETE(state, index) {
    state.duzenle_rezervasyon.sepet_urunler.splice(index, 1);
  },

  SET_DUZENLE_REZERVASYON(state, payload) {
    state.duzenle_rezervasyon = payload;
  },

  SET_SEPET_DUZENLE_REZERVASYON_RESET(state) {
    state.duzenle_rezervasyon = {
      k_no: null,
      uye: null,
      acente_k_no: null,
      lang: 'tr',
      urun_tipi: null,
      sepet_urunler: [],
      promosyon_kodu: null,
      satis_yontemi: 'direk',
      rezervasyon_satis_durum: 'direk',
      misafir: {
        ad: null,
        soyad: null,
        dogum_tarihi: null,
        tc_pasaport: null,
        adres: null,
        telefon: null,
        e_mail: null,
      },
      rezervasyon_notu: null,
      hotel_bilgi: {
        hotel_adi: null,
        oda_no: null,
        sehir: null,
        ilce: null,
      },
      kdv_turu: 'dahil',
      kdv_oran: 0,
      sabit_kur: 'TRY',
      sabit_kur_fiyat: 0,
      secilen_kur: null,
      secilen_kur_fiyat: 0,
      rezervasyon_toplam: {
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        iskonto_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      kur_rezervasyon_toplam: {
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        iskonto_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      gun_fiyatlar: [],

      odeme_yontemi: 'kredi_karti',
      odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
      odeme_tipi: 'on_odeme',
      tahsilat_durum: 0,
      banka_k_no: null,
      kart_bilgileri: {
        kart_ad_soyad: null,
        kart_numarasi: null,
        kart_ay_yil: null,
        kart_ccv: null,
        kart_3d_guvenlik: false,
        kart_taksit: 1,
      },
      mesafeli_satis_sozlesmesi: true,
      kiralama_sozlesmesi: true,
    };
  },

  SET_SEPET_DUZENLE_URUN_PUSH(state, payload) {
    state.duzenle_rezervasyon.sepet_urunler.push(payload);
  },

  SET_SEPET_DUZENLE_URUN_DELETE(state, index) {
    state.duzenle_rezervasyon.sepet_urunler.splice(index, 1);
  },
};
const actions = {};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
