import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  dashboard: [],
};
const getters = {
  getDashboardAnaliz: (state) => state.dashboard,
};
const mutations = {
  SET_DASHBOARD(state, payload) {
    state.dashboard = payload;
  },
  SET_DASHBOARD_PUSH(state, payload) {
    state.dashboard.push(payload);
  },
};
const actions = {
  async dashboardAnaliz({ commit }) {
    return await axiosIns.post('/home/dashboard-analiz').then((res) => {
      if (res.data.success == true) {
        commit('SET_DASHBOARD', res.data.data);
        return res;
      }
    });
  },
  async dashboardSonGelenRezervansyonlar({ commit }, payload) {
    return await axiosIns.post('/home/dashboard-son-rezervasyonlar', payload).then((res) => {
      if (res.data.success == true) {
        return res;
      }
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
