import axiosIns from '@/libs/axios';
const state = {
  transfer_ayarlar: [],
};
const getters = {
  getTransferAyarlar: (state) => state.transfer_ayarlar,
};
const mutations = {
  SET_TRANSFER_AYARLAR(state, payload) {
    state.transfer_ayarlar = payload;
  },
};
const actions = {
  async transferAyarlarGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/transfer-ayarlar-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_TRANSFER_AYARLAR', res.data.data);
      } else {
        commit('SET_TRANSFER_AYARLAR', data);
      }
      return res;
    });
  },
  async transferAyarlarEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/transfer-ayarlar-ekle', payload).then((res) => {
      commit('SET_TRANSFER_AYARLAR', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
