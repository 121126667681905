import axiosIns from '@/libs/axios';
const state = {
  sms_ayarlari: [],
};
const getters = {
  getSmsAyarlari: (state) => state.sms_ayarlari,
};
const mutations = {
  SET_SMS(state, payload) {
    state.sms_ayarlari = payload;
  },
  SET_SMS_DELETE(state, k_no) {
    let index = state.sms_ayarlari.findIndex((x) => x.k_no == k_no);
    state.sms_ayarlari.splice(index, 1);
  },
};
const actions = {
  async smsAyarlariGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/sms-ayarlari-getir', payload).then((res) => {
      commit('SET_SMS', res.data.data);
      return res;
    });
  },
  async smsAyarlariEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/sms-ayarlari-ekle', payload).then((res) => {
      commit('SET_SMS', res.data.data);
      return res;
    });
  },
  async smsAyarlariSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/sms-ayarlar-sil', payload).then((res) => {
      commit('SET_SMS_DELETE', k_no);
      return res;
    });
  },

  async smsGonder({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/sms-gonder-post', payload).then((res) => {
      commit('SET_SMS', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
