import axiosIns from '@/libs/axios';
const state = {
  hotel_api_ayar: [],
};
const getters = {
  getHotelApiAyarlari: (state) => state.hotel_api_ayar,
};
const mutations = {
  SET_HOTEL_API(state, payload) {
    state.hotel_api_ayar = payload;
  },
  SET_HOTEL_API_DELETE(state, k_no) {
    let index = state.hotel_api_ayar.findIndex((x) => x.k_no == k_no);
    state.hotel_api_ayar.splice(index, 1);
  },
};
const actions = {
  async hotelApiAyarlariGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/hotel-api-ayarlari-getir', payload).then((res) => {
      commit('SET_HOTEL_API', res.data.data);
      return res;
    });
  },
  async hotelApiAyarlariEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/hotel-api-ayarlari-ekle', payload).then((res) => {
      commit('SET_HOTEL_API', res.data.data);
      return res;
    });
  },
  async hotelApiAyarlariSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/hotel-api-ayarlari-sil', payload).then((res) => {
      commit('SET_HOTEL_API_DELETE', k_no);
      return res;
    });
  },

  async hotelApiAyarlariTest({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/hotel-api-ayarlari-test-post', payload).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
