export default [
  {
    path: '/destek-hatti',
    name: 'destek-hatti',
    redirect: '/destek-hatti/listele',
    component: () => import(/* webpackChunkName: "acenteler" */ '@/views/destek/index.vue'),
    children: [
      {
        path: '/destek-hatti/listele',
        name: 'destek-hatti-listele',
        component: () => import(/* webpackChunkName: "destek-hatti-listele" */ '@/views/destek/listele.vue'),
        meta: { title: 'Destek Hattı', noCache: true }
      },
      {
        path: '/destek-hatti/detay/:k_no?',
        name: 'destek-hatti-detay',
        component: () => import(/* webpackChunkName: "destek-hatti-detay" */ '@/views/destek/detay.vue'),
        meta: { title: 'Destek Hattı Detay', noCache: false }
      },
    ],
  },
];
