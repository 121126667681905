const state = {
  tag_views: [],
};
const getters = {
  getTagViews: (state) => state.tag_views,
};
const mutations = {
  ADD_VISITED_VIEW: (state, view) => {
    if (!state.tag_views.some(v => v.path === view.path)) {
      state.tag_views.push({
        meta: view.meta,
        path: view.path,
        fullPath: view.fullPath,
        query: view.query
      })
    } else {
      const index = state.tag_views.findIndex(x => x.path == view.path);
      if (index != -1) {
        state.tag_views[index] = {
          meta: view.meta,
          path: view.path,
          fullPath: view.fullPath,
          query: view.query
        }
      }
    }
  },

  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.tag_views.entries()) {
      if (v.path === view.path) {
        state.tag_views.splice(i, 1)
        break
      }
    }
  },

  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    const affixTags = state.tag_views.filter(tag => tag.meta.affix)
    state.tag_views = affixTags
  },

};
const actions = {
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view)
  },


  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },

  deleteView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view)
      resolve({
        tag_views: [...state.tag_views],
      })
    })
  },

  delAllViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS')
      resolve({ tag_views: [...state.tag_views] })
    })
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
