export default [
  {
    path: '/urun/urunler',
    name: 'urunler',
    redirect: '/urun/urun/listele',
    component: () => import(/* webpackChunkName: "urunler" */ '@/views/urunGruplari/villaTurAracUrunleri/index.vue'),
    children: [
      {
        path: '/urun/urun/listele',
        name: 'urun-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/urunGruplari/villaTurAracUrunleri/listele.vue'),
        meta: { title: 'Ürünler', noCache: true },
      },
      {
        path: '/urun/urun/ekle',
        name: 'urun-ekle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/urunGruplari/villaTurAracUrunleri/urunEkle.vue'),
        meta: { title: 'Ürün Ekle', noCache: true },
      },
      {
        path: '/urun/urun/sirala',
        name: 'urun-sirala',
        component: () =>
          import(/* webpackChunkName: "sayfa-sirala" */ '@/views/urunGruplari/villaTurAracUrunleri/urunSirala.vue'),
        meta: { title: 'Ürün Sırala', noCache: true },
      },
      {
        path: '/urun/urun/maliyet-fiyat',
        name: 'urun-maliyet-fiyat',
        component: () =>
          import(/* webpackChunkName: "sayfa-maliyet-fiyat" */ '@/views/urunGruplari/villaTurAracUrunleri/urunMaliyetFiyat.vue'),
        meta: { title: 'Ürün Maliyet Fiyat', noCache: true },
      },
      {
        path: '/urun/urun/guncelle/:k_no?',
        name: 'urun-guncelle',
        component: () => import(/* webpackChunkName: "urun-guncelle" */ '@/views/urunGruplari/villaTurAracUrunleri/urunEkle.vue'),
        meta: { title: 'Ürün Güncelle', noCache: false },
      },
      {
        path: '/urun/urun/hareketler/:k_no?',
        name: 'urun-hareketler',
        component: () =>
          import(/* webpackChunkName: "urun-hareketler" */ '@/views/urunGruplari/villaTurAracUrunleri/hareketler.vue'),
        meta: { title: 'Ürün Hareketler', noCache: false },
      },
    ],
  },
  {
    path: '/urun/transfer-urunler',
    name: 'transfer-urunler',
    redirect: '/urun/transfer/listele',
    component: () => import(/* webpackChunkName: "urunler" */ '@/views/urunGruplari/transferUrunleri/index.vue'),
    children: [
      {
        path: '/urun/transfer/listele',
        name: 'transfer-urun-listele',
        component: () => import(/* webpackChunkName: "transfer-urun-listele" */ '@/views/urunGruplari/transferUrunleri/index.vue'),
        meta: { title: 'Transfer Tarifeleri', noCache: true },
      },
      // {
      //   path: '/urun/transfer/ekle',
      //   name: 'urun-ekle',
      //   component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/urunGruplari/transferUrunleri/urunEkle.vue'),
      // },
      // {
      //   path: '/urun/transfer/guncelle/:k_no?',
      //   name: 'urun-guncelle',
      //   component: () => import(/* webpackChunkName: "urun-guncelle" */ '@/views/urunGruplari/transferUrunleri/urunEkle.vue'),
      // },
      // {
      //   path: '/urun/transfer/hareketler/:k_no?',
      //   name: 'urun-hareketler',
      //   component: () => import(/* webpackChunkName: "urun-hareketler" */ '@/views/urunGruplari/transferUrunleri/hareketler.vue'),
      // },
    ],
  },
  {
    path: '/urun/urun-takvimi',
    name: 'urun-takvimi',
    redirect: '/urun/takvim/listele',
    component: () => import(/* webpackChunkName: "urun-takvimi" */ '@/views/urunGruplari/transferUrunleri/index.vue'),
    children: [
      {
        path: '/urun/takvim/listele',
        name: 'urun-takvim-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/urunGruplari/transferUrunleri/index.vue'),
        meta: { title: 'Ürün Takvim', noCache: true },
      },
    ],
  },
];
