import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  stoklar: [],
};
const getters = {
  getUrunStoklar: (state) => state.stoklar,
};
const mutations = {
  SET_STOKLAR(state, payload) {
    state.stoklar = payload;
  },
  SET_STOKLAR_PUSH(state, payload) {
    state.stoklar.push(payload);
  },
  SET_STOKLAR_UPDATE(state, payload) {
    let index = state.stoklar.findIndex((x) => x.k_no == payload.k_no);
    state.stoklar[index] = payload;
  },
  SET_STOKLAR_DELETE(state, k_no) {
    let altindex = state.stoklar.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.stoklar.splice(altindex, 1);
    }
    let index = state.stoklar.findIndex((x) => x.k_no == k_no);
    state.stoklar.splice(index, 1);
  },
};
const actions = {
  async urunStokHareketListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-stok-hareket-listele', payload).then((res) => {
      commit('SET_STOKLAR', res.data.data);
      return res;
    });
  },
  async urunStokHareketEkle({ commit }, data) {
    return await axiosIns.post('/urunler/urun-stok-hareket-ekle', data).then((res) => {
      commit('SET_STOKLAR_PUSH', res.data.data);
      return res;
    });
  },
  async urunStokHareketGuncelle({ commit }, data) {
    return await axiosIns.post('/urunler/urun-stok-hareket-guncelle', data).then((res) => {
      commit('SET_STOKLAR_UPDATE', res.data.data);
      return res;
    });
  },
  async urunStokHareketSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/urunler/urun-stok-hareket-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async urunStokHareketSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/urunler/urun-stok-hareket-sil', payload).then((res) => {
      commit('SET_STOKLAR_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
