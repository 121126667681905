import axiosIns from '@/libs/axios';
const state = {
  yorumlar: [],
};
const getters = {
  getYorumlar: (state) => state.yorumlar,
};
const mutations = {
  SET_YORUM(state, payload) {
    state.yorumlar = payload;
  },
  SET_YORUM_PUSH(state, payload) {
    state.yorumlar.push(payload);
  },
  SET_YORUM_UPDATE(state, payload) {
    let index = state.yorumlar.findIndex((x) => x._id == payload._id);
    state.yorumlar[index] = payload;
  },
  SET_YORUM_DELETE(state, k_no) {
    let index = state.yorumlar.findIndex((x) => x.k_no == k_no);
    state.yorumlar.splice(index, 1);
  },
};
const actions = {
  async yorumListele({ commit }, data) {
    return await axiosIns.post('/yorumlar/yorum-listele', data).then((res) => {
      commit('SET_YORUM', res.data.data);
      return res;
    });
  },

  async yorumEkle({ commit }, payload) {
    return await axiosIns
      .post('/yorumlar/yorum-ekle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          commit('SET_YORUM_PUSH', res.data.data);
        }
        return res;
      });
  },

  async yorumGuncelle({ commit }, payload) {
    return await axiosIns
      .post('/yorumlar/yorum-guncelle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_YORUM_UPDATE', res.data.data);
        return res;
      });
  },

  async yorumSil({ commit }, k_no) {
    return await axiosIns.post('/yorumlar/yorum-sil', { k_no }).then((res) => {
      commit('SET_YORUM_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
