export default [
  {
    path: '/yonetim/firmalar',
    name: 'firmalar',
    redirect: '/yonetim/firmalar/listele',
    component: () => import(/* webpackChunkName: "firmalar" */ '@/views/yonetim/firmalar/index.vue'),
    children: [
      {
        path: '/yonetim/firmalar/listele',
        name: 'firma-listele',
        component: () => import(/* webpackChunkName: "firma-listele" */ '@/views/yonetim/firmalar/listele.vue'),
        meta: { title: 'Firmalar', noCache: false },
      },
      // {
      //   path: '/yonetim/firmalar/detay/:k_no',
      //   name: 'firma-detay',
      //   component: () => import(/* webpackChunkName: "firma-detay" */ '@/views/yonetim/firmalar/firmaDetay.vue'),
      // },
      // {
      //   path: '/yonetim/firma/ekle',
      //   name: 'firma-ekle',
      //   component: () => import(/* webpackChunkName: "firma-ekle" */ '@/views/yonetim/firmalar/firmaTanimla.vue'),
      // },
      // {
      //   path: '/yonetim/firma/guncelle/:k_no?',
      //   name: 'firma-guncelle',
      //   component: () => import(/* webpackChunkName: "firma-ekle" */ '@/views/yonetim/firmalar/firmaTanimla.vue'),
      // },
    ],
  },
];
