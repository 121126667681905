import axiosIns from '@/libs/axios';
const state = {
  destek: {},
};
const getters = {
  getDestekAyarlari: (state) => state.destek,
};
const mutations = {
  SET_DESTEK_AYARLARI(state, payload) {
    state.destek = payload;
  },
};
const actions = {
  async destekAyarlariGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/destek-ayarlari-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_DESTEK_AYARLARI', res.data.data);
      } else {
        commit('SET_DESTEK_AYARLARI', data);
      }
      return res;
    });
  },
  async destekAyarlariEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/destek-ayarlari-ekle', payload).then((res) => {
      commit('SET_DESTEK_AYARLARI', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
