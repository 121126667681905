import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  rezervasyon: {
    k_no: null,
    uye_k_no: null,
    acente_k_no: null,
    rezervasyon_tarihi: {
      giris: null,
      cikis: null,
    },
    rezervasyon_bolgesi: {
      alis_bolgesi: null,
      teslim_bolgesi: null,
    },
    lang: 'tr',
    urun_tipi: null,
    urunler: [],
    sepet_urunler: [],
    transfer_urunler: [],
    seans_saati: null,
    bolge_alis_saati: null,
    promosyon_kodu: null,
    satis_yontemi: 'direk',
    misafir: {
      ad: null,
      soyad: null,
      dogum_tarihi: null,
      tc_pasaport: null,
      adres: null,
      telefon: null,
      e_mail: null,
    },
    diger_misafir: [],
    rezervasyon_notu: null,
    pax: {
      yetiskin: 1,
      cocuk: 0,
      bebek: 0,
    },
    hotel_bilgi: {
      hotel_adi: null,
      oda_no: null,
      sehir: null,
      ilce: null,
    },

    kdv_turu: 'dahil',
    kdv_oran: 0,
    sabit_kur: 'TRY',
    sabit_kur_fiyat: 0,
    secilen_kur: null,
    secilen_kur_fiyat: 0,
    rezervasyon_toplam: {
      toplam_gun: 0,
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
    },
    kur_rezervasyon_toplam: {
      toplam_gun: 0,
      urun_toplam: 0,
      ekstra_toplam: 0,
      kupon_indirim: 0,
      kdv_toplam: 0,
      taksit_fark: 0,
      ara_toplam: 0,
      genel_toplam: 0,
      on_odeme_oran: 0,
      on_odeme_toplam: 0,
      kalan_toplam: 0,
    },
    gun_fiyatlar: [],
    ekstra_hizmet_k_no: null,
    ekstra_hizmetler: [],
    odeme_yontemi: 'kredi_karti',
    odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
    odeme_tipi: 'on_odeme',
    tahsilat_durum: 0,
    banka_k_no: null,
    kart_bilgileri: {
      kart_ad_soyad: null,
      kart_numarasi: null,
      kart_ay_yil: null,
      kart_ccv: null,
      kart_3d_guvenlik: false,
      kart_taksit: 1,
    },
    mesafeli_satis_sozlesmesi: true,
    kiralama_sozlesmesi: true,
  },
};
const getters = {
  getRezervasyonlarBasket: (state) => state.rezervasyon,
};
const mutations = {
  SET_VILLA_REZERVASYON(state, payload) {
    state.rezervasyon = payload;
  },

  SET_VILLA_REZERVASYON_RESET(state) {
    state.rezervasyon = {
      k_no: null,
      uye_k_no: null,
      acente_k_no: null,
      rezervasyon_tarihi: {
        giris: null,
        cikis: null,
      },
      rezervasyon_bolgesi: {
        alis_bolgesi: null,
        teslim_bolgesi: null,
      },
      lang: 'tr',
      urun_tipi: null,
      urunler: [],
      sepet_urunler: [],
      transfer_urunler: [],
      seans_saati: null,
      bolge_alis_saati: null,
      promosyon_kodu: null,
      satis_yontemi: 'direk',
      misafir: {
        ad: null,
        soyad: null,
        dogum_tarihi: null,
        tc_pasaport: null,
        adres: null,
        telefon: null,
        e_mail: null,
      },
      diger_misafir: [],
      rezervasyon_notu: null,
      pax: {
        yetiskin: 1,
        cocuk: 0,
        bebek: 0,
      },
      hotel_bilgi: {
        hotel_adi: null,
        oda_no: null,
        sehir: null,
        ilce: null,
      },
      kdv_turu: 'dahil',
      kdv_oran: 0,
      sabit_kur: 'TRY',
      sabit_kur_fiyat: 0,
      secilen_kur: null,
      secilen_kur_fiyat: 0,
      rezervasyon_toplam: {
        toplam_gun: 0,
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      kur_rezervasyon_toplam: {
        toplam_gun: 0,
        urun_toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        kdv_toplam: 0,
        taksit_fark: 0,
        ara_toplam: 0,
        genel_toplam: 0,
        on_odeme_oran: 0,
        on_odeme_toplam: 0,
        kalan_toplam: 0,
        tahsilat_toplam: 0,
      },
      gun_fiyatlar: [],
      ekstra_hizmet_k_no: null,
      ekstra_hizmetler: [],
      odeme_yontemi: 'kredi_karti',
      odeme_tipi_kontrol: 'on_odeme', //vtye yazılmayacak sadece kontrol amaclı
      odeme_tipi: 'on_odeme',
      tahsilat_durum: 0,
      banka_k_no: null,
      kart_bilgileri: {
        kart_ad_soyad: null,
        kart_numarasi: null,
        kart_ay_yil: null,
        kart_ccv: null,
        kart_3d_guvenlik: false,
        kart_taksit: 1,
      },
      mesafeli_satis_sozlesmesi: true,
      kiralama_sozlesmesi: true,
    };
  },

  SET_SEPET_URUN_PUSH(state, payload) {
    state.rezervasyon.sepet_urunler.push(payload);
  },

  SET_SEPET_URUN_DELETE(state, index) {
    state.rezervasyon.sepet_urunler.splice(index, 1);
    // if (tip == 'tur') {
    // } else {
    //   state.rezervasyon.transfer_urunler.splice(index, 1);
    // }
  },
};
const actions = {};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
