<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { provideToast } from 'vue-toastification/composition';
import { computed, watch, defineComponent } from '@vue/composition-api';
const LayoutVertical = () => import('@/layouts/vertical/VerticalLayout.vue');
const LayoutFull = () => import('@/layouts/full/FullLayout.vue');
import { useRouter } from '@/libs/utils';
import store from './store';
export default defineComponent({
  components: {
    LayoutVertical,
    LayoutFull,
  },
  setup() {
    const expo = {};
    const { route } = useRouter();

    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    expo.layout = computed(() => {
      if (route.value.meta.layout === 'full') return 'layout-full';
      return `layout-vertical`;
    });

    store.dispatch('initAuth');

    return { ...expo };
  },
});
</script>

<style>
/* @import './assets/scss/sytle.scss'; */
</style>
