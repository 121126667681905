import axiosIns from '@/libs/axios';
const state = {
  urun_satis: [],
};
const getters = {
  getUrunSatisRapor: (state) => state.urun_satis,
};
const mutations = {
  SET_URUN_SATIS(state, payload) {
    state.urun_satis = payload;
  },
  SET_URUN_SATIS_PUSH(state, payload) {
    state.urun_satis.push(payload);
  },
};
const actions = {
  async urunSatisRaporuListele({ commit }, data) {
    return await axiosIns.post('/raporlar/urun-satis-rapor-listele', data).then((res) => {
      commit('SET_URUN_SATIS', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
