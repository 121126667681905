import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  renk_tanimlari: [],
};
const getters = {
  getRenkTanimlari: (state) => state.renk_tanimlari,
};
const mutations = {
  SET_RENKTANIMLAR(state, payload) {
    state.renk_tanimlari = payload;
  },
  SET_RENKTANIMLAR_PUSH(state, payload) {
    state.renk_tanimlari.push(payload);
  },
  SET_RENKTANIMLAR_UPDATE(state, payload) {
    let index = state.renk_tanimlari.findIndex((x) => x.k_no == payload.k_no);
    state.renk_tanimlari[index] = payload;
  },
  SET_RENKTANIMLAR_DELETE(state, k_no) {
    let altindex = state.renk_tanimlari.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.renk_tanimlari.splice(altindex, 1);
    }
    let index = state.renk_tanimlari.findIndex((x) => x.k_no == k_no);
    state.renk_tanimlari.splice(index, 1);
  },
};
const actions = {
  async renkTanimlaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/renk-tanimla-listele', payload).then((res) => {
      commit('SET_RENKTANIMLAR', res.data.data);
      return res;
    });
  },
  async renkTanimlaEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/renk-tanimla-ekle', data).then((res) => {
      commit('SET_RENKTANIMLAR_PUSH', res.data.data);
      return res;
    });
  },
  async renkTanimlaGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/renk-tanimla-guncelle', data).then((res) => {
      commit('SET_RENKTANIMLAR_UPDATE', res.data.data);
      return res;
    });
  },
  async renkTanimlaSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/renk-tanimla-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async renkTanimlaSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/renk-tanimla-sil', payload).then((res) => {
      commit('SET_RENKTANIMLAR_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
