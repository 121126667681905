import Vue from 'vue';

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import BootstrapVue from 'bootstrap-vue';

import VueCompositionAPI from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';

import '@/assets/scss/app.scss';
import tr from 'vee-validate/dist/locale/tr.json';
import * as rules from 'vee-validate/dist/rules';

import router from './router';
import store from './store';
import App from './App.vue';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize('tr', tr);

// Install VeeValidate components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import '@/libs/filters';
import '@/libs/vue-mask';
import '@/libs/vue-ck-editor';
import '@/libs/toastification';
import '@/libs/vue-sticky';
import './registerServiceWorker'

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
