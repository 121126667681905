import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  firma_bilgi: [],
};
const getters = {
  getFirmaBilgileri: (state) => state.firma_bilgi,
};
const mutations = {
  SET_FIRMA_BILGI(state, payload) {
    state.firma_bilgi = payload;
  },
  SET_FIRMA_BILGI_DELETE(state, k_no) {
    let index = state.firma_bilgi.findIndex((x) => x.k_no == k_no);
    state.firma_bilgi.splice(index, 1);
  },
};
const actions = {
  async firmaBilgiGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/firma-bilgi-getir', payload).then((res) => {
      commit('SET_FIRMA_BILGI', res.data.data);
      return res;
    });
  },
  async firmaBilgiEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/firma-bilgi-ekle', data).then((res) => {
      commit('SET_FIRMA_BILGI', res.data.data);
      return res;
    });
  },
  async firmaBilgiSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/firma-bilgi-sil', payload).then((res) => {
      commit('SET_FIRMA_BILGI_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
