import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  hoteller: [],
};
const getters = {
  getHoteller: (state) => state.hoteller,
};
const mutations = {
  SET_HOTELLER(state, payload) {
    state.hoteller = payload;
  },
  SET_HOTELLER_PUSH(state, payload) {
    state.hoteller.push(payload);
  },
  SET_HOTELLER_UPDATE(state, payload) {
    let index = state.hoteller.findIndex((x) => x.k_no == payload.k_no);
    state.hoteller[index] = payload;
  },
  SET_HOTELLER_DELETE(state, k_no) {
    let index = state.hoteller.findIndex((x) => x.k_no == k_no);

    if (index != -1 && index != undefined) {
      state.hoteller.splice(index, 1);
    }
  },
};
const actions = {
  async hotelListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/hotel-listele', payload).then((res) => {
      commit('SET_HOTELLER', res.data.data);
      return res;
    });
  },
  async hotelEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-ekle', data).then((res) => {
      commit('SET_HOTELLER_PUSH', res.data.data);
      return res;
    });
  },
  async hotelGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-guncelle', data).then((res) => {
      commit('SET_HOTELLER_UPDATE', res.data.data);
      return res;
    });
  },
  async hotelSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async hotelSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/hotel-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_HOTELLER_DELETE', k_no);
      }
      return res;
    });
  },
  async hotelGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/tanimlamalar/hotel-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_HOTELLER_UPDATE', res.data.data);
        return res;
      });
  },
  async hotelGorselSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-gorsel-sira-guncelle', data).then((res) => {
      commit('SET_HOTELLER_UPDATE', res.data.data);
      return res;
    });
  },
  async hotelGorselKapakGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-gorsel-kapak-guncelle', data).then((res) => {
      commit('SET_HOTELLER_UPDATE', res.data.data);
      return res;
    });
  },
  async hotelGorselSil({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-gorsel-sil', data).then((res) => {
      commit('SET_HOTELLER_UPDATE', res.data.data);
      return res;
    });
  },

  async hotelApiEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/hotel-api-ekle', data).then((res) => {
      commit('SET_HOTELLER_PUSH', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
