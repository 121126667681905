import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  kullanicilar: [],
  roller: [],
};
const getters = {
  getKullanicilar: (state) => state.kullanicilar,
  getRoller: (state) => state.roller,
};
const mutations = {
  // KULLANICILAR
  SET_KULLANICILAR(state, payload) {
    state.kullanicilar = payload;
  },
  SET_KULLANICILAR_PUSH(state, payload) {
    state.kullanicilar.push(payload);
  },
  SET_KULLANICILAR_UPDATE(state, payload) {
    let index = state.kullanicilar.findIndex((x) => x.k_no == payload.k_no);
    state.kullanicilar[index] = payload;
  },
  SET_KULLANICILAR_DELETE(state, k_no) {
    let index = state.kullanicilar.findIndex((x) => x.k_no == k_no);
    state.kullanicilar.splice(index, 1);
  },

  // KULLANICILAR
  SET_ROLLER(state, payload) {
    state.roller = payload;
  },
  SET_ROLLER_PUSH(state, payload) {
    state.roller.push(payload);
  },
  SET_ROLLER_UPDATE(state, payload) {
    let index = state.roller.findIndex((x) => x.k_no == payload.k_no);
    state.roller[index] = payload;
  },
  SET_ROLLER_DELETE(state, k_no) {
    let index = state.roller.findIndex((x) => x.k_no == k_no);
    state.roller.splice(index, 1);
  },
};
const actions = {
  // Kullanici İşlemleri
  async kullaniciListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };

    return await axiosIns.post('/kullanicilar/kullanici-listele', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_KULLANICILAR', res.data.data);
      }
    });
  },
  async kullaniciEkle({ commit }, payload) {
    return await axiosIns
      .post('/kullanicilar/kullanici-ekle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_KULLANICILAR_PUSH', res.data.data);
        return res;
      });
  },
  async kullaniciGuncelle({ commit }, payload) {
    return await axiosIns
      .post('/kullanicilar/kullanici-guncelle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_KULLANICILAR_UPDATE', res.data.data);
        return res;
      });
  },
  async kullaniciSil({ commit }, k_no) {
    return await axiosIns.post('/kullanicilar/kullanici-sil', { k_no }).then((res) => {
      commit('SET_KULLANICILAR_DELETE', k_no);
      return res;
    });
  },

  async kullaniciGetir({ commit }, payload) {
    return await axiosIns.post('/kullanicilar/kullanici-getir', payload).then((res) => {
      return res;
    });
  },

  // Kullanici İşlemleri
  async rolleListele({ commit }, firma_k_no) {
    return await axiosIns.post('/kullanicilar/rolle-listele', { firma_k_no }).then((res) => {
      if (res.data.success == true) {
        commit('SET_ROLLER', res.data.data);
      }
    });
  },
  async rolleEkle({ commit }, payload) {
    return await axiosIns.post('/kullanicilar/rolle-ekle', payload).then((res) => {
      commit('SET_ROLLER_PUSH', res.data.data);
      return res;
    });
  },
  async rolleGuncelle({ commit }, payload) {
    return await axiosIns.post('/kullanicilar/rolle-guncelle', payload).then((res) => {
      commit('SET_ROLLER_UPDATE', res.data.data);
      return res;
    });
  },
  async rolleSil({ commit }, k_no) {
    return await axiosIns.post('/kullanicilar/rolle-sil', { k_no }).then((res) => {
      commit('SET_ROLLER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
