import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  seo: [],
};
const getters = {
  getSeo: (state) => state.seo,
};
const mutations = {
  SET_SEO(state, payload) {
    state.seo = payload;
  },
  SET_SEO_DELETE(state, k_no) {
    let index = state.seo.findIndex((x) => x.k_no == k_no);
    state.seo.splice(index, 1);
  },
};
const actions = {
  async seoGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/seo-getir', payload).then((res) => {
      commit('SET_SEO', res.data.data);
      return res;
    });
  },
  async seoEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/seo-ekle', payload).then((res) => {
      commit('SET_SEO', res.data.data);
      return res;
    });
  },
  async seoSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/seo-sil', payload).then((res) => {
      commit('SET_SMS_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
