import axiosIns from '@/libs/axios';
const state = {
  acenteler: [],
};
const getters = {
  getAcenteler: (state) => state.acenteler,
};
const mutations = {
  SET_ACENTE(state, payload) {
    state.acenteler = payload;
  },
  SET_ACENTE_PUSH(state, payload) {
    if (payload.ac_k_no == null) state.acenteler.push(payload);
  },
  SET_ACENTE_UPDATE(state, payload) {
    let index = state.acenteler.findIndex((x) => x.k_no == payload.k_no);
    state.acenteler[index] = payload;
  },
  SET_ACENTE_DELETE(state, k_no) {
    let altindex = state.acenteler.findIndex((x) => x.ac_k_no == k_no);
    if (altindex != -1) {
      state.acenteler.splice(altindex, 1);
    }
    let index = state.acenteler.findIndex((x) => x.k_no == k_no);
    state.acenteler.splice(index, 1);
  },
};
const actions = {
  async acenteListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/acente/acente-listele', payload).then((res) => {
      commit('SET_ACENTE', res.data.data);
      return res;
    });
  },
  async acenteEkle({ commit }, data) {
    return await axiosIns.post('/acente/acente-ekle', data).then((res) => {
      commit('SET_ACENTE_PUSH', res.data.data);
      return res;
    });
  },
  async acenteGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-guncelle', data).then((res) => {
      commit('SET_ACENTE_UPDATE', res.data.data);
      return res;
    });
  },

  async acenteLogoGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-logo-guncelle', data).then((res) => {
      commit('SET_ACENTE_UPDATE', res.data.data);
      return res;
    });
  },

  async acenteLogoSil({ commit }, data) {
    return await axiosIns.post('/acente/acente-logo-sil', data).then((res) => {
      commit('SET_ACENTE_UPDATE', res.data.data);
      return res;
    });
  },

  async acenteStatuGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-statu-guncelle', data).then((res) => {
      return res;
    });
  },
  async acenteSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/acente/acente-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_ACENTE_DELETE', k_no);
      }
      return res;
    });
  },
  async acenteFindOne({ commit }, payload) {
    return await axiosIns.post('/acente/acente-find-one', payload).then((res) => {
      return res;
    });
  },
  async acenteGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/acente/acente-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_ACENTE_UPDATE', res.data.data);
        return res;
      });
  },
  async acenteGorselSil({ commit }, data) {
    return await axiosIns.post('/acente/acente-gorsel-sil', data).then((res) => {
      commit('SET_ACENTE_UPDATE', res.data.data);
      return res;
    });
  },

  async acenteTumDatalariGetir({ commit }, data) {
    return await axiosIns.post('/acente/acente-tum-data-listele', data).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
