import axiosIns from '@/libs/axios';
const state = {
  diger_ayarlar: [],
};
const getters = {
  getDigerAyarlar: (state) => state.diger_ayarlar,
};
const mutations = {
  SET_DIGER_AYARLAR(state, payload) {
    state.diger_ayarlar = payload;
  },
};
const actions = {
  async digerAyarlarGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/diger-ayarlar-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_DIGER_AYARLAR', res.data.data);
      } else {
        commit('SET_DIGER_AYARLAR', data);
      }
      return res;
    });
  },
  async digerAyarlarEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/diger-ayarlar-ekle', payload).then((res) => {
      commit('SET_DIGER_AYARLAR', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
