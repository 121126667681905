import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  eposta_ayarlari: [],
};
const getters = {
  getEpostaAyarlari: (state) => state.eposta_ayarlari,
};
const mutations = {
  SET_EPOSTA(state, payload) {
    state.eposta_ayarlari = payload;
  },
  SET_EPOSTA_DELETE(state, k_no) {
    let index = state.eposta_ayarlari.findIndex((x) => x.k_no == k_no);
    state.eposta_ayarlari.splice(index, 1);
  },
};
const actions = {
  async epostaAyarlariGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/eposta-ayarlari-getir', payload).then((res) => {
      commit('SET_EPOSTA', res.data.data);
      return res;
    });
  },
  async epostaAyarlariEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/eposta-ayarlari-ekle', payload).then((res) => {
      commit('SET_EPOSTA', res.data.data);
      return res;
    });
  },
  async epostaAyarlariSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/eposta-ayarlar-sil', payload).then((res) => {
      commit('SET_SMS_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
