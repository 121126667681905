import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  urunler: [],
};
const getters = {
  getUrunler: (state) => state.urunler,
};
const mutations = {
  SET_URUNLER(state, payload) {
    state.urunler = payload;
  },
  SET_URUNLER_PUSH(state, payload) {
    state.urunler.push(payload);
  },
  SET_URUNLER_UPDATE(state, payload) {
    let index = state.urunler.findIndex((x) => x.k_no == payload.k_no);
    state.urunler[index] = payload;
  },
  SET_URUNLER_DELETE(state, k_no) {
    let index = state.urunler.findIndex((x) => x.k_no == k_no);
    state.urunler.splice(index, 1);
  },
};
const actions = {
  async UrunListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-listele', payload).then((res) => {
      commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async UrunEkle({ commit }, data) {
    return await axiosIns.post('/urunler/urun-ekle', data).then((res) => {
      commit('SET_URUNLER_PUSH', res.data.data);
      return res;
    });
  },
  async UrunGuncelle({ commit }, data) {
    return await axiosIns.post('/urunler/urun-guncelle', data).then((res) => {
      commit('SET_URUNLER_UPDATE', res.data.data);
      return res;
    });
  },
  async UrunBannerSil({ }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-banner-sil', payload).then((res) => {
      return res;
    });
  },
  async UrunGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/urunler/urun-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_URUNLER_UPDATE', res.data.data);
        return res;
      });
  },
  async UrunGorselSiraGuncelle({ }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/urunler/urun-gorsel-sira-guncelle', payload).then((res) => {
      return res;
    });
  },
  async UrunGorselKapakGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-gorsel-kapak', payload).then((res) => {
      commit('SET_URUNLER_UPDATE', res.data.data);
      return res;
    });
  },
  async UrunGorselSil({ }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-gorsel-sil', payload).then((res) => {
      return res;
    });
  },
  async UrunSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/urunler/urun-sil', payload).then((res) => {
      commit('SET_URUNLER_DELETE', k_no);
      return res;
    });
  },
  async UrunTopluSil({ commit }, payload) {
    return await axiosIns.post('/urunler/urun-toplu-sil', payload).then((res) => {
      if (res.data.success == true) {
        payload.k_no.forEach((element) => {
          commit('SET_URUNLER_DELETE', element.k_no);
        });
      }
      return res;
    });
  },
  async UrunKopyala({ commit }, payload) {
    return await axiosIns.post('/urunler/urun-kopyala', payload).then((res) => {
      return res;
    });
  },
  async UrunTopluGuncelle({ commit }, payload) {
    return await axiosIns.post('/urunler/urun-toplu-guncelle', payload).then((res) => {
      return res;
    });
  },
  async UrunFindOne({ }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/urunler/urun-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },

  async urunSiralamaListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/urunler/urun-siralama-listele', { params: payload }).then((res) => {
      commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async urunSiralamaGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-siralama-guncelle', payload).then((res) => {
      return res;
    });
  },

  async urunMaliyetFiyatListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/urunler/urun-maliyet-fiyat-listele', { params: payload }).then((res) => {
      commit('SET_URUNLER', res.data.data);
      return res;
    });
  },
  async urunMaliyetFiyatGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/urun-maliyet-fiyat-guncelle', payload).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
