import axiosIns from '@/libs/axios';
const state = {
  siparis_rapor: [],
};
const getters = {
  getSiparisRapor: (state) => state.siparis_rapor,
};
const mutations = {
  SET_SIPARIS(state, payload) {
    state.siparis_rapor = payload;
  },
  SET_SIPARIS_PUSH(state, payload) {
    state.siparis_rapor.push(payload);
  },
};
const actions = {
  async siparisRaporuListele({ commit }, data) {
    return await axiosIns.post('/raporlar/siparis-rapor-listele', data).then((res) => {
      commit('SET_SIPARIS', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
