import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  e_tic_siparisler: [],
};
const getters = {
  getETicaretSiparisler: (state) => state.e_tic_siparisler,
};
const mutations = {
  SET_ETICSIPARIS(state, payload) {
    state.e_tic_siparisler = payload;
  },
  SET_ETICSIPARIS_PUSH(state, payload) {
    state.e_tic_siparisler.push(payload);
  },
  SET_ETICSIPARIS_UPDATE(state, payload) {
    let index = state.e_tic_siparisler.findIndex((x) => x.k_no == payload.k_no);
    state.e_tic_siparisler[index] = payload;
  },
  SET_ETICSIPARIS_DELETE(state, k_no) {
    let index = state.e_tic_siparisler.findIndex((x) => x.k_no == k_no);
    state.e_tic_siparisler.splice(index, 1);
  },
};
const actions = {
  async eTicSiparisListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/siparisler/e-tic-siparis-listele', payload).then((res) => {
      commit('SET_ETICSIPARIS', res.data.data);
      return res;
    });
  },
  async eTicSiparisEkle({ commit }, data) {
    return await axiosIns.post('/siparisler/e-tic-siparis-ekle', data).then((res) => {
      commit('SET_ETICSIPARIS_PUSH', res.data.data);
      return res;
    });
  },
  async eTicSiparisGuncelle({ commit }, data) {
    return await axiosIns.post('/siparisler/e-tic-siparis-guncelle', data).then((res) => {
      commit('SET_ETICSIPARIS_UPDATE', res.data.data);
      return res;
    });
  },
  async eTicSiparisSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/siparisler/e-tic-siparis-sil', payload).then((res) => {
      commit('SET_ETICSIPARIS_DELETE', k_no);
      return res;
    });
  },
  async eTicSiparisFindOne({}, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/siparisler/e-tic-siparis-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },

  async eTicSiparisMailGonder({ commit }, data) {
    return await axiosIns.post('/siparisler/e-tic-siparis-mail-gonder', data).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
