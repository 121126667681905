import axiosIns from '@/libs/axios';
const state = {
  acente_kullanici_yetkiler: [],
};
const getters = {
  getAcenteKullaniciYetkiler: (state) => state.acente_kullanici_yetkiler,
};
const mutations = {
  SET_ACENTE_KULLANICI_YETKI(state, payload) {
    state.acente_kullanici_yetkiler = payload;
  },
  SET_ACENTE_KULLANICI_YETKI_PUSH(state, payload) {
    if (payload.ac_k_no == null) state.acente_kullanici_yetkiler.push(payload);
  },
  SET_ACENTE_KULLANICI_YETKI_UPDATE(state, payload) {
    let index = state.acente_kullanici_yetkiler.findIndex((x) => x.k_no == payload.k_no);
    state.acente_kullanici_yetkiler[index] = payload;
  },
  SET_ACENTE_KULLANICI_YETKI_DELETE(state, k_no) {
    let altindex = state.acente_kullanici_yetkiler.findIndex((x) => x.ac_k_no == k_no);
    if (altindex != -1) {
      state.acente_kullanici_yetkiler.splice(altindex, 1);
    }
    let index = state.acente_kullanici_yetkiler.findIndex((x) => x.k_no == k_no);
    state.acente_kullanici_yetkiler.splice(index, 1);
  },
};
const actions = {
  async acenteKullaniciYetkiListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/acente/acente-kullanici-yetki-listele', payload).then((res) => {
      commit('SET_ACENTE_KULLANICI_YETKI', res.data.data);
      return res;
    });
  },
  async acenteKullaniciYetkiEkle({ commit }, data) {
    return await axiosIns.post('/acente/acente-kullanici-yetki-ekle', data).then((res) => {
      commit('SET_ACENTE_KULLANICI_YETKI_PUSH', res.data.data);
      return res;
    });
  },
  async acenteKullaniciYetkiGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-kullanici-yetki-guncelle', data).then((res) => {
      commit('SET_ACENTE_KULLANICI_YETKI_UPDATE', res.data.data);
      return res;
    });
  },
  async acenteKullaniciYetkiSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/acente/acente-kullanici-yetki-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_ACENTE_KULLANICI_YETKI_DELETE', k_no);
      }
      return res;
    });
  },
  async acenteKullaniciYetkiFindOne({ commit }, payload) {
    return await axiosIns.post('/acente/acente-kullanici-yetki-find-one', payload).then((res) => {
      return res;
    });
  },

  async acenteKullaniciYetkileriGetir({ commit }, payload) {
    return await axiosIns.post('/acente/acente-kullanici-yetki-getir', payload).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
