export default [
  {
    path: '/acenteler',
    name: 'acenteler',
    redirect: '/acente/listele',
    component: () => import(/* webpackChunkName: "acenteler" */ '@/views/acenteler/index.vue'),
    children: [
      {
        path: '/acente/listele',
        name: 'acente-listele',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/index.vue'),
        meta: { title: 'Acenteler', noCache: true }
      },
      {
        path: '/acente/ekle',
        name: 'acente-ekle',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteEkle.vue'),
        meta: { title: 'Acente Ekle', noCache: true }
      },
      {
        path: '/acente/guncelle/:k_no?',
        name: 'acente-guncelle',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteEkle.vue'),
        meta: { title: 'Acente Güncelle', noCache: false }

      },
      {
        path: '/acente/detail/:k_no?',
        name: 'acente-detail',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteDetail.vue'),
        meta: { title: 'Acente Detay', noCache: false }

      },
      {
        path: '/acente/detail/:k_no?/rezervasyonlar',
        name: 'acente-rezervasyon',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteRezervasyonlar.vue'),
        meta: { title: 'Acente Rezervasyonlar', noCache: false }
      },
      {
        path: '/acente/detail/:k_no?/komisyonlar',
        name: 'acente-komisyon',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteKomisyonlar.vue'),
        meta: { title: 'Acente Komisyonlar', noCache: false }
      },
      {
        path: '/acente/detail/:k_no?/finans',
        name: 'acente-finans',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteFinansal.vue'),
        meta: { title: 'Acente Finans', noCache: false }
      },
      {
        path: '/acente/detail/:k_no?/alt-acente',
        name: 'acente-alt-acente',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteAltAcente.vue'),
        meta: { title: 'Acente Alt Üyeler', noCache: false }
      },
      {
        path: '/acente/detail/:k_no?/kullanicilar',
        name: 'acente-kullacilar',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteKomisyonlar.vue'),
        meta: { title: 'Acente Kullanıcılar', noCache: false }
      },
      {
        path: '/acente/detail/:k_no?/destek',
        name: 'acente-destek',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/listele/AcenteDestekHatti.vue'),
        meta: { title: 'Acente Destek', noCache: false }
      },
      {
        path: '/acente/kullanici/listele',
        name: 'acente-kullanici-listele',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/kullanicilar/index.vue'),
        meta: { title: 'Acente Kullanıcılar', noCache: false }
      },
      {
        path: '/acente/kullanici/ekle',
        name: 'acente-kullanici-ekle',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/kullanicilar/KullaniciEkle.vue'),
        meta: { title: 'Acente Kullanıcı Ekle', noCache: false }
      },
      {
        path: '/acente/kullanici/guncelle/:k_no?',
        name: 'acente-kullanici-guncelle',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/kullanicilar/KullaniciEkle.vue'),
        meta: { title: 'Acente Kullanıcı Güncelle', noCache: false }
      },
      {
        path: '/acente/kullanici/yetki/listele',
        name: 'acente-kullanici-yetki-listele',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/kullaniciYetkiler/index.vue'),
        meta: { title: 'Acente Kullanıcı Yetki Listesi', noCache: false }
      },
      {
        path: '/acente/kullanici/yetki/ekle',
        name: 'acente-kullanici-yetki-ekle',
        component: () =>
          import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/kullaniciYetkiler/kullaniciYetkiEkle.vue'),
        meta: { title: 'Acente Kullanıcı Yetki Ekle', noCache: false }
      },
      {
        path: '/acente/kullanici/yetki/guncelle/:k_no?',
        name: 'acente-kullanici-yetki-guncelle',
        component: () =>
          import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/kullaniciYetkiler/kullaniciYetkiEkle.vue'),
        meta: { title: 'Acente Kullanıcı Yetki Güncelle', noCache: false }
      },
      {
        path: '/acente/destek/listele',
        name: 'acente-destek-listele',
        component: () => import(/* webpackChunkName: "acente-detail" */ '@/views/acenteler/destek/index.vue'),
        meta: { title: 'Acente Destek Listele', noCache: false }
      },
      // {
      //   path: '/acente/ekle',
      //   name: 'acente-ekle',
      //   component: () => import(/* webpackChunkName: "acente-ekle" */ '@/views/urunGruplari/acenteler/acenteEkle.vue'),
      // },
      // {
      //   path: '/acente/guncelle/:k_no?',
      //   name: 'acente-guncelle',
      //   component: () => import(/* webpackChunkName: "acente-guncelle" */ '@/views/urunGruplari/acenteler/acenteEkle.vue'),
      // },
      // {
      //   path: '/acente/hareketler/:k_no?',
      //   name: 'acente-hareketler',
      //   component: () => import(/* webpackChunkName: "acente-hareketler" */ '@/views/urunGruplari/acenteler/hareketler.vue'),
      // },
    ],
  },
];
