export default [
  {
    path: '/rezervasyon/rezervasyonlar',
    name: 'rezervasyonlar',
    redirect: '/rezervasyon/listele',
    component: () => import(/* webpackChunkName: "rezervasyonlar" */ '@/views/rezervasyonlar/index.vue'),
    children: [
      {
        path: '/rezervasyon/listele',
        name: 'rezervasyon-listele',
        component: () => import(/* webpackChunkName: "rezervasyon-listele" */ '@/views/rezervasyonlar/listele.vue'),
        meta: { title: 'Rezervasyonlar', noCache: true }
      },
      // {
      //   path: '/rezervasyon/ekle',
      //   name: 'rezervasyon-ekle',
      //   component: () => import(/* webpackChunkName: "rezervasyon-ekle" */ '@/views/rezervasyonlar/rezervasyonEkle.vue'),
      // },
      {
        path: '/rezervasyon/urunler',
        name: 'rezervasyon-urunler',
        component: () => import(/* webpackChunkName: "rezervasyon-urunler" */ '@/views/rezervasyonlar/rezervasyonUrunler.vue'),
        meta: { title: 'Rezervasyon Ekle', noCache: false }
      },
      {
        path: '/rezervasyon/sepet',
        name: 'rezervasyon-sepet',
        component: () => import(/* webpackChunkName: "rezervasyon-sepet" */ '@/views/rezervasyonlar/rezervasyonSepet.vue'),
        meta: { title: 'Rezervasyon Sepet', noCache: false }
      },
      {
        path: '/rezervasyon/sorgula',
        name: 'rezervasyon-sorgula',
        component: () => import(/* webpackChunkName: "rezervasyon-sepet" */ '@/views/rezervasyonlar/rezervasyonSorgula.vue'),
        meta: { title: 'Rezervasyon Sorgula', noCache: false }
      },
      {
        path: '/rezervasyon/guncelle/:k_no?',
        name: 'rezervasyon-guncelle',
        component: () => import(/* webpackChunkName: "rezervasyon-guncelle" */ '@/views/rezervasyonlar/rezervasyonGuncelle.vue'),
        meta: { title: 'Rezervasyon Güncelle', noCache: false }
      },
      {
        path: '/rezervasyon/detay/:k_no?',
        name: 'rezervasyon-detay',
        component: () => import(/* webpackChunkName: "rezervasyon-detay" */ '@/views/rezervasyonlar/rezervasyonDetay.vue'),
        meta: { title: 'Rezervasyon Detay', noCache: false }
      },
    ],
  },
  {
    path: '/rezervasyon/fis/:k_no?/:price?',
    name: 'rezervasyon-fisi',
    component: () => import(/* webpackChunkName: "rezervasyon-fisi" */ '@/views/rezervasyonlar/rezervasyonYazdir.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: false,
      title: 'Rezervasyon Fiş', noCache: false
    },
  },
  // {
  //   path: '/rezervasyon/detay/:k_no?/kargo-fisi',
  //   name: 'kargo-fisi',
  //   component: () => import(/* webpackChunkName: "kargo-fisi" */ '@/views/rezervasyonlar/kargoFisi.vue'),
  //   meta: {
  //     layout: 'full',
  //     redirectIfLoggedIn: false,
  //   },
  // },
  {
    path: '/rezervasyon/on-talep',
    name: 'on-talepler',
    redirect: '/rezervasyon/on-talep/listele',
    component: () => import(/* webpackChunkName: "on-talepler" */ '@/views/rezervasyonlar/onTalepler/index.vue'),
    children: [
      {
        path: '/rezervasyon/on-talep/listele',
        name: 'on-talep-listele',
        component: () => import(/* webpackChunkName: "on-listele" */ '@/views/rezervasyonlar/onTalepler/listele.vue'),
        meta: { title: 'Ön Talepler', noCache: true }
      },
    ],
  },
  {
    path: '/rezervasyon/ozel-talep',
    name: 'ozel-talepler',
    redirect: '/rezervasyon/ozel-talep/listele',
    component: () => import(/* webpackChunkName: "ozel-talepler" */ '@/views/rezervasyonlar/ozelTalepler/index.vue'),
    children: [
      {
        path: '/rezervasyon/ozel-talep/listele',
        name: 'ozel-talep-listele',
        component: () => import(/* webpackChunkName: "ozel-listele" */ '@/views/rezervasyonlar/ozelTalepler/listele.vue'),
        meta: { title: 'Özel Talepler', noCache: true }
      },
    ],
  },
  {
    path: '/rezervasyon/iptal-talep',
    name: 'iptal-talepler',
    redirect: '/rezervasyon/iptal-talep/listele',
    component: () => import(/* webpackChunkName: "iptal-talepler" */ '@/views/rezervasyonlar/iptalTalepler/index.vue'),
    children: [
      {
        path: '/rezervasyon/iptal-talep/listele',
        name: 'iptal-talep-listele',
        component: () => import(/* webpackChunkName: "iptal-listele" */ '@/views/rezervasyonlar/iptalTalepler/listele.vue'),
        meta: { title: 'İptal Talepler', noCache: true }
      },
    ],
  },
  {
    path: '/rezervasyon/odeme-bildirim',
    name: 'odeme-bildirim',
    redirect: '/rezervasyon/odeme-bildirim/listele',
    component: () => import(/* webpackChunkName: "odeme-bildirimler" */ '@/views/rezervasyonlar/odemeBildirim/index.vue'),
    children: [
      {
        path: '/rezervasyon/odeme-bildirim/listele',
        name: 'odeme-bildirim-listele',
        component: () => import(/* webpackChunkName: "odeme-listele" */ '@/views/rezervasyonlar/odemeBildirim/listele.vue'),
        meta: { title: 'Ödeme Bildirim', noCache: true }
      },
    ],
  },
];
