import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  uye_gruplari: [],
};
const getters = {
  getUyeGrupListele: (state) => state.uye_gruplari,
};
const mutations = {
  SET_UYEGRUP(state, payload) {
    state.uye_gruplari = payload;
  },
  SET_UYEGRUP_PUSH(state, payload) {
    state.uye_gruplari.push(payload);
  },
  SET_UYEGRUP_UPDATE(state, payload) {
    let index = state.uye_gruplari.findIndex((x) => x.k_no == payload.k_no);
    state.uye_gruplari[index] = payload;
  },
  SET_UYEGRUP_DELETE(state, k_no) {
    let altindex = state.uye_gruplari.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.uye_gruplari.splice(altindex, 1);
    }
    let index = state.uye_gruplari.findIndex((x) => x.k_no == k_no);
    state.uye_gruplari.splice(index, 1);
  },
};
const actions = {
  async uyeGrupListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/uye-grup-listele', payload).then((res) => {
      commit('SET_UYEGRUP', res.data.data);
      return res;
    });
  },
  async uyeGrupEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/uye-grup-ekle', data).then((res) => {
      commit('SET_UYEGRUP_PUSH', res.data.data);
      return res;
    });
  },
  async uyeGrupGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/uye-grup-guncelle', data).then((res) => {
      commit('SET_UYEGRUP_UPDATE', res.data.data);
      return res;
    });
  },
  async uyeGrupSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/uye-grup-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_UYEGRUP_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
