import axiosIns from '@/libs/axios';
import store from '@/store';

const state = {
  kur_ayarlari: [],
};
const getters = {
  getKurAyarlari: (state) => state.kur_ayarlari,
};
const mutations = {
  SET_KUR_AYARLARI(state, payload) {
    state.kur_ayarlari = payload;
  },
};
const actions = {
  async kurAyarlariGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/kur-ayarlari-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_KUR_AYARLARI', res.data.data);
        const varsayilan = res.data.data.varsayilan;
        const kur = store.getters.getKurListesi.find((x) => x.banka_kodu == varsayilan);
        commit('setExchange', kur);
      } else {
        commit('SET_KUR_AYARLARI', data);
      }
      return res;
    });
  },
  async kurAyarlariEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/kur-ayarlari-ekle', payload).then((res) => {
      commit('SET_KUR_AYARLARI', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
