export default [
  {
    path: '/magaza/sayfalar',
    name: 'sayfalar',
    redirect: '/magaza/sayfa/listele',
    component: () => import(/* webpackChunkName: "sayfalar" */ '@/views/magaza/sayfalar/index.vue'),
    children: [
      {
        path: '/magaza/sayfa/listele',
        name: 'sayfa-listele',
        component: () => import(/* webpackChunkName: "sayfa-listele" */ '@/views/magaza/sayfalar/listele.vue'),
        meta: { title: 'Sayfalar', noCache: true }
      },
      {
        path: '/magaza/sayfa/ekle',
        name: 'sayfa-ekle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/magaza/sayfalar/sayfaEkle.vue'),
        meta: { title: 'Sayfa Ekle', noCache: true }
      },
      {
        path: '/magaza/sayfa/guncelle/:k_no?',
        name: 'sayfa-guncelle',
        component: () => import(/* webpackChunkName: "sayfa-ekle" */ '@/views/magaza/sayfalar/sayfaEkle.vue'),
        meta: { title: 'Sayfa Guncelle', noCache: false }
      },
    ],
  },
  {
    path: '/magaza/slider',
    name: 'slider',
    component: () => import(/* webpackChunkName: "slider" */ '@/views/magaza/slider/index.vue'),
    meta: { title: 'Slider', noCache: true }
  },
  {
    path: '/magaza/banner',
    name: 'banner',
    component: () => import(/* webpackChunkName: "banner" */ '@/views/magaza/banner/index.vue'),
    meta: { title: 'Banner', noCache: true }
  },
  {
    path: '/magaza/splash-bildirimler',
    name: 'splash-bildirimler',
    component: () => import(/* webpackChunkName: "splash-bildirimler" */ '@/views/magaza/splashBildirimler/index.vue'),
    meta: { title: 'Splash Bildirim', noCache: true }
  },
];
