import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  logo_favicon: {},
};
const getters = {
  getLogoFavicon: (state) => state.logo_favicon,
};
const mutations = {
  SET_LOGO_FAVICON(state, payload) {
    state.logo_favicon = payload;
  },
};
const actions = {
  async logoFaviconGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/logo-favicon-getir', payload).then((res) => {
      commit('SET_LOGO_FAVICON', res.data.data);
      return res;
    });
  },
  async logoEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/logo-ekle', data).then((res) => {
      commit('SET_LOGO_FAVICON', res.data.data);
      return res;
    });
  },
  async logoSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/logo-sil', payload).then((res) => {
      commit('SET_LOGO_FAVICON', res.data.data);
      return res;
    });
  },
  async faviconEkle({ commit }, data) {
    return await axiosIns.post('/ayarlar/favicon-ekle', data).then((res) => {
      commit('SET_LOGO_FAVICON', res.data.data);
      return res;
    });
  },
  async faviconSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/favicon-sil', payload).then((res) => {
      commit('SET_LOGO_FAVICON', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
