import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  transfer_araclar: [],
};
const getters = {
  getTransferAraclar: (state) => state.transfer_araclar,
};
const mutations = {
  SET_TRANSFER_ARACLAR(state, payload) {
    state.transfer_araclar = payload;
  },

  SET_TRANSFER_ARACLAR_PUSH(state, payload) {
    state.transfer_araclar.push(payload);
  },
  SET_TRANSFER_ARACLAR_UPDATE(state, payload) {
    let index = state.transfer_araclar.findIndex((x) => x.k_no == payload.k_no);
    state.transfer_araclar[index] = payload;
  },
  SET_TRANSFER_ARACLAR_DELETE(state, k_no) {
    let index = state.transfer_araclar.findIndex((x) => x.k_no == k_no);

    if (index != -1 && index != undefined) {
      state.transfer_araclar.splice(index, 1);
    }
  },
};
const actions = {
  async transferAracListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/transfer-arac-listele', payload).then((res) => {
      commit('SET_TRANSFER_ARACLAR', res.data.data);
      return res;
    });
  },
  async transferAracEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/transfer-arac-ekle', data).then((res) => {
      if (data.bolge_k_no != null) {
        commit('SET_TRANSFER_ARACLAR_UPDATE', res.data.data);
      } else {
        commit('SET_TRANSFER_ARACLAR_PUSH', res.data.data);
      }
      return res;
    });
  },
  async transferAracGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/transfer-arac-guncelle', data).then((res) => {
      commit('SET_TRANSFER_ARACLAR', res.data.data);
      return res;
    });
  },
  async transferAracSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/transfer-arac-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async transferAracSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/transfer-arac-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_TRANSFER_ARACLAR_DELETE', k_no);
      }
      return res;
    });
  },
  async transferAracGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/tanimlamalar/transfer-arac-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_TRANSFER_ARACLAR_UPDATE', res.data.data);
        return res;
      });
  },
  async transferAracGorselSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/transfer-arac-gorsel-sira-guncelle', data).then((res) => {
      commit('SET_TRANSFER_ARACLAR_UPDATE', res.data.data);
      return res;
    });
  },
  async transferAracGorselKapakGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/transfer-arac-gorsel-kapak-guncelle', data).then((res) => {
      commit('SET_TRANSFER_ARACLAR_UPDATE', res.data.data);
      return res;
    });
  },
  async transferAracGorselSil({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/transfer-arac-gorsel-sil', data).then((res) => {
      commit('SET_TRANSFER_ARACLAR_UPDATE', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
