import axiosIns from '@/libs/axios';
const state = {
  acente_komisyon: {},
};
const getters = {
  getAcenteKomisyon: (state) => state.acente_komisyon,
};
const mutations = {

};
const actions = {
  async acenteKomisyonListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.get('/acente/acente-komisyon-listele', { params: payload }).then((res) => {
      return res;
    });
  },
  async acenteKomisyonGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-komisyon-guncelle', data).then((res) => {
      return res;
    });
  },

};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
