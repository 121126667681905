import axiosIns from '@/libs/axios';
import store from '@/store';
import { langs } from '@/libs/languages';
const state = {
  uyelik_yonetimi: [],
};
const getters = {
  getUyelikYonetimi: (state) => state.uyelik_yonetimi,
};
const mutations = {
  SET_UYELIK_YONETIMI(state, payload) {
    state.uyelik_yonetimi = payload;
  },
};
const actions = {
  async uyelikYonetimiGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/uyelik-yonetimi-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_UYELIK_YONETIMI', res.data.data);
      } else {
        commit('SET_UYELIK_YONETIMI', data);
      }
      return res;
    });
  },
  async uyelikYonetimiEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/uyelik-yonetimi-ekle', payload).then((res) => {
      commit('SET_UYELIK_YONETIMI', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
