import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  bankalar: [],
};
const getters = {
  getBankalar: (state) => state.bankalar,
};
const mutations = {
  SET_BANKA(state, payload) {
    state.bankalar = payload;
  },
  SET_BANKA_PUSH(state, payload) {
    state.bankalar.push(payload);
  },
  SET_BANKA_UPDATE(state, payload) {
    let index = state.bankalar.findIndex((x) => x.k_no == payload.k_no);
    state.bankalar[index] = payload;
  },
  SET_BANKA_DELETE(state, k_no) {
    let index = state.bankalar.findIndex((x) => x.k_no == k_no);
    state.bankalar.splice(index, 1);
  },
};
const actions = {
  async bankaListele({ commit }, payload) {
    return await axiosIns.post('/ayarlar/banka-listele', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_BANKA', res.data.data);
      }
    });
  },
  async bankaEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/banka-ekle', payload).then((res) => {
      commit('SET_BANKA_PUSH', res.data.data);
      return res;
    });
  },
  async bankaGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/banka-guncelle', payload).then((res) => {
      commit('SET_BANKA_UPDATE', res.data.data);
      return res;
    });
  },
  async bankaSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/ayarlar/banka-sil', payload).then((res) => {
      commit('SET_BANKA_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
