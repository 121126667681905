import axiosIns from '@/libs/axios';
const state = {
  acente_rezervasyonlar: [],
};
const getters = {
  getAcenteRezervasyonlar: (state) => state.acente_rezervasyonlar,
};
const mutations = {
  SET_ACENTE_HAREKET(state, payload) {
    state.acente_rezervasyonlar = payload;
  },
  SET_ACENTE_HAREKET_PUSH(state, payload) {
    state.acente_rezervasyonlar.push(payload);
  },
  SET_ACENTE_HAREKET_UPDATE(state, payload) {
    let index = state.acente_rezervasyonlar.findIndex((x) => x.k_no == payload.k_no);
    state.acente_rezervasyonlar[index] = payload;
  },
  SET_ACENTE_HAREKET_DELETE(state, k_no) {
    let altindex = state.acente_rezervasyonlar.findIndex((x) => x.ac_k_no == k_no);
    if (altindex != -1) {
      state.acente_rezervasyonlar.splice(altindex, 1);
    }
    let index = state.acente_rezervasyonlar.findIndex((x) => x.k_no == k_no);
    state.acente_rezervasyonlar.splice(index, 1);
  },
};
const actions = {
  async acenteRezervasyonListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/acente/acente-rezervasyon-listele', payload).then((res) => {
      commit('SET_ACENTE_HAREKET', res.data.data);
      return res;
    });
  },
  async acenteHareketEkle({ commit }, data) {
    return await axiosIns.post('/acente/acente-hareket-ekle', data).then((res) => {
      commit('SET_ACENTE_HAREKET_PUSH', res.data.data);
      return res;
    });
  },
  async acenteHareketGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-hareket-guncelle', data).then((res) => {
      commit('SET_ACENTE_HAREKET_UPDATE', res.data.data);
      return res;
    });
  },

  async acenteHareketSil({ commit }, data) {
    return await axiosIns.post('/acente/acente-hareket-sil', data).then((res) => {
      commit('SET_ACENTE_HAREKET_DELETE', data.k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
