import axiosIns from '@/libs/axios';
const state = {
  acente_kullanicilar: [],
};
const getters = {
  getAcenteKullanicilar: (state) => state.acente_kullanicilar,
};
const mutations = {
  SET_ACENTE_KULLANICI(state, payload) {
    state.acente_kullanicilar = payload;
  },

  SET_ACENTE_KULLANICI_DELETE(state, k_no) {
    let altindex = state.acente_kullanicilar.findIndex((x) => x.ac_k_no == k_no);
    if (altindex != -1) {
      state.acente_kullanicilar.splice(altindex, 1);
    }
    let index = state.acente_kullanicilar.findIndex((x) => x.k_no == k_no);
    state.acente_kullanicilar.splice(index, 1);
  },
};
const actions = {
  async acenteKullaniciListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/acente/acente-kullanici-listele', payload).then((res) => {
      commit('SET_ACENTE_KULLANICI', res.data.data);
      return res;
    });
  },
  async acenteKullaniciEkle({ commit }, data) {
    return await axiosIns.post('/acente/acente-kullanici-ekle', data).then((res) => {
      return res;
    });
  },
  async acenteKullaniciGuncelle({ commit }, data) {
    return await axiosIns.post('/acente/acente-kullanici-guncelle', data).then((res) => {
      return res;
    });
  },
  async acenteKullaniciSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/acente/acente-kullanici-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_ACENTE_KULLANICI_DELETE', k_no);
      }
      return res;
    });
  },
  async acenteKullaniciFindOne({ commit }, payload) {
    return await axiosIns.post('/acente/acente-kullanici-find-one', payload).then((res) => {
      return res;
    });
  },
  async acenteKullaniciGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/acente/acente-kullanici-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_ACENTE_KULLANICI_UPDATE', res.data.data);
        return res;
      });
  },
  async acenteKullaniciGorselSil({ commit }, data) {
    return await axiosIns.post('/acente/acente-kullanici-gorsel-sil', data).then((res) => {
      commit('SET_ACENTE_KULLANICI_UPDATE', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
