import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  bolgeler: [],
  tum_bolgeler: [],
  secili_bolgeler: [], // ürüne eklenmiş bölgeler
};
const getters = {
  getBolgeler: (state) => state.bolgeler,
  getTumBolgeler: (state) => state.tum_bolgeler,
  getSeciliBolgeler: (state) => state.secili_bolgeler,
};
const mutations = {
  SET_BOLGELER(state, payload) {
    state.tum_bolgeler = payload;
    let bolgeler = [];
    let sBolgeler = [];
    payload.forEach((el) => {
      if (el.bolge_k_no == null) bolgeler.push({ ...el, children: [] });
    });

    payload.forEach((el, i) => {
      if (el.bolge_k_no != null) {
        let index = bolgeler.findIndex((x) => x.k_no == el.bolge_k_no);
        if (index != -1) {
          bolgeler[index].children.push(el);
        }
      }
    });

    payload.forEach((el, i) => {
      if (el.urun > 0) {
        sBolgeler.push(el);
      }
    });

    state.secili_bolgeler = sBolgeler;

    state.bolgeler = bolgeler;
  },
  SET_BOLGELER_PUSH(state, payload) {
    state.bolgeler.push(payload);
  },
  SET_BOLGELER_UPDATE(state, payload) {
    let index = state.bolgeler.findIndex((x) => x.k_no == payload.k_no);
    state.bolgeler[index] = payload;
  },
  SET_BOLGELER_DELETE(state, k_no) {
    state.bolgeler.forEach((el, index) => {
      let altindex = el.children.findIndex((x) => x.k_no == k_no);
      if (altindex != -1) {
        state.bolgeler[index].children.splice(altindex, 1);
      }
    });
    let index = state.bolgeler.find((x) => x.k_no == k_no);

    if (index != -1 && index != undefined) {
      state.bolgeler.splice(index, 1);
    }
  },
};
const actions = {
  async bolgeListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/bolge-listele', payload).then((res) => {
      commit('SET_BOLGELER', res.data.data);
      return res;
    });
  },
  async bolgeEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-ekle', data).then((res) => {
      if (data.bolge_k_no != null) {
        commit('SET_BOLGELER_UPDATE', res.data.data);
      } else {
        commit('SET_BOLGELER_PUSH', res.data.data);
      }
      return res;
    });
  },
  async bolgeGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-guncelle', data).then((res) => {
      commit('SET_BOLGELER', res.data.data);
      return res;
    });
  },
  async bolgeSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async bolgeSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/bolge-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_BOLGELER_DELETE', k_no);
      }
      return res;
    });
  },
  async bolgeGorselEkle({ commit }, data) {
    return await axiosIns
      .post('/tanimlamalar/bolge-gorsel-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_BOLGELER_UPDATE', res.data.data);
        return res;
      });
  },
  async bolgeGorselSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-gorsel-sira-guncelle', data).then((res) => {
      commit('SET_BOLGELER_UPDATE', res.data.data);
      return res;
    });
  },
  async bolgeGorselKapakGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-gorsel-kapak-guncelle', data).then((res) => {
      commit('SET_BOLGELER_UPDATE', res.data.data);
      return res;
    });
  },
  async bolgeGorselSil({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/bolge-gorsel-sil', data).then((res) => {
      commit('SET_BOLGELER_UPDATE', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
