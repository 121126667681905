import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  variant_grup: [],
};
const getters = {
  getVariantGruplari: (state) => state.variant_grup,
};
const mutations = {
  SET_VARIANTLAR(state, payload) {
    state.variant_grup = payload;
  },
  SET_VARIANTLAR_PUSH(state, payload) {
    state.variant_grup.push(payload);
  },
  SET_VARIANTLAR_UPDATE(state, payload) {
    let index = state.variant_grup.findIndex((x) => x.k_no == payload.k_no);
    state.variant_grup[index] = payload;
  },
  SET_VARIANTLAR_DELETE(state, k_no) {
    let altindex = state.variant_grup.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.variant_grup.splice(altindex, 1);
    }
    let index = state.variant_grup.findIndex((x) => x.k_no == k_no);
    state.variant_grup.splice(index, 1);
  },
};
const actions = {
  async variantGrupListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/variant-grup-listele', payload).then((res) => {
      commit('SET_VARIANTLAR', res.data.data);
      return res;
    });
  },
  async variantGrupEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/variant-grup-ekle', data).then((res) => {
      commit('SET_VARIANTLAR_PUSH', res.data.data);
      return res;
    });
  },
  async variantGrupGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/variant-grup-guncelle', data).then((res) => {
      commit('SET_VARIANTLAR_UPDATE', res.data.data);
      return res;
    });
  },
  async variantGrupSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/variant-grup-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async variantGrupSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/variant-grup-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_VARIANTLAR_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
