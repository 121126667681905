export default [
  {
    path: '/ayarlar',
    name: 'ayarlar',
    redirect: '/ayarlar/menu',
    component: () => import(/* webpackChunkName: "ayarlar" */ '@/views/ayarlar/index.vue'),
    children: [
      {
        path: '/ayarlar/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '@/views/ayarlar/menu/index.vue'),
        meta: { title: 'Menü Ayar', noCache: true }
      },
      {
        path: '/ayarlar/seo',
        name: 'seo',
        component: () => import(/* webpackChunkName: "seo" */ '@/views/ayarlar/seo/index.vue'),
        meta: { title: 'Seo Ayar', noCache: true }
      },
      {
        path: '/ayarlar/iletisim',
        name: 'iletisim',
        component: () => import(/* webpackChunkName: "iletisim" */ '@/views/ayarlar/iletisim/index.vue'),
        meta: { title: 'İletişim', noCache: true }
      },
      {
        path: '/ayarlar/destek',
        name: 'destek',
        component: () => import(/* webpackChunkName: "destek" */ '@/views/ayarlar/destek/index.vue'),
        meta: { title: 'Destek Ayar', noCache: true }
      },
      {
        path: '/ayarlar/dil',
        name: 'dil',
        component: () => import(/* webpackChunkName: "dil" */ '@/views/ayarlar/dil/index.vue'),
        meta: { title: 'Dil Ayar', noCache: true }
      },
      {
        path: '/ayarlar/uyelik',
        name: 'uyelik',
        component: () => import(/* webpackChunkName: "uyelik" */ '@/views/ayarlar/uyelik/index.vue'),
        meta: { title: 'Üyelik Ayar', noCache: true }
      },
      {
        path: '/ayarlar/kur',
        name: 'kur',
        component: () => import(/* webpackChunkName: "kur" */ '@/views/ayarlar/kur/index.vue'),
        meta: { title: 'Kur Ayar', noCache: true }
      },
      {
        path: '/ayarlar/mail',
        name: 'mail',
        component: () => import(/* webpackChunkName: "mail" */ '@/views/ayarlar/mail/index.vue'),
        meta: { title: 'Mail Ayar', noCache: true }
      },
      {
        path: '/ayarlar/sms',
        name: 'sms',
        component: () => import(/* webpackChunkName: "sms" */ '@/views/ayarlar/sms/index.vue'),
        meta: { title: 'Sms Ayar', noCache: true }
      },
      {
        path: '/ayarlar/mail-bildirim',
        name: 'mail-bildirim',
        component: () => import(/* webpackChunkName: "mail-bildirim" */ '@/views/ayarlar/mailBildirim/index.vue'),
        meta: { title: 'Mail Bildirim', noCache: true }
      },
      {
        path: '/ayarlar/sosyal',
        name: 'sosyal',
        component: () => import(/* webpackChunkName: "sosyal" */ '@/views/ayarlar/sosyal/index.vue'),
        meta: { title: 'Sosyal Ağ Ayar', noCache: true }
      },
      {
        path: '/ayarlar/logo',
        name: 'logo',
        component: () => import(/* webpackChunkName: "logo" */ '@/views/ayarlar/logo/index.vue'),
        meta: { title: 'Logo Ayar', noCache: true }
      },
      {
        path: '/ayarlar/firma',
        name: 'firma',
        component: () => import(/* webpackChunkName: "firma" */ '@/views/ayarlar/firma/index.vue'),
        meta: { title: 'Firma Ayar', noCache: true }
      },
      {
        path: '/ayarlar/odeme-sistemi',
        name: 'odeme-sistemi',
        component: () => import(/* webpackChunkName: "odeme-sistemi" */ '@/views/ayarlar/odemeSistemi/index.vue'),
        meta: { title: 'Ödeme Ayar', noCache: true }
      },
      {
        path: '/ayarlar/banka',
        name: 'banka',
        component: () => import(/* webpackChunkName: "banka" */ '@/views/ayarlar/banka/index.vue'),
        meta: { title: 'Banka Ayar', noCache: true }
      },
      {
        path: '/ayarlar/hotel-api',
        name: 'hotel-api',
        component: () => import(/* webpackChunkName: "diger" */ '@/views/ayarlar/hotelApi/index.vue'),
        meta: { title: 'Hotel Api Ayar', noCache: true }
      },
      {
        path: '/ayarlar/diger',
        name: 'diger',
        component: () => import(/* webpackChunkName: "diger" */ '@/views/ayarlar/diger/index.vue'),
        meta: { title: 'Diger Ayar', noCache: true }
      },
      {
        path: '/ayarlar/yedekleme',
        name: 'yedekleme',
        component: () => import(/* webpackChunkName: "yedekleme" */ '@/views/ayarlar/yedekleme/index.vue'),
        meta: { title: 'Yedekleme', noCache: true }
      },
    ],
  },
  {
    path: '/ayarlar/tema-ayar',
    name: 'tema-ayar',
    component: () => import(/* webpackChunkName: "tema-ayar" */ '@/views/ayarlar/temaAyar/index.vue'),
    meta: { title: 'Tema Ayar', noCache: true }
  },
  {
    path: '/kullanici/profilim',
    name: 'profilim',
    component: () => import(/* webpackChunkName: "profilim" */ '@/views/kullanicilar/kullaniciProfil/index.vue'),
    meta: { title: 'Profil', noCache: true }
  },
];
