import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  rez_durumlari: [],
};
const getters = {
  getRezervasyonDurumlari: (state) => state.rez_durumlari,
};
const mutations = {
  SET_REZERVASYONDURUM(state, payload) {
    state.rez_durumlari = payload;
  },
  SET_REZERVASYONDURUM_PUSH(state, payload) {
    state.rez_durumlari.push(payload);
  },
  SET_REZERVASYONDURUM_UPDATE(state, payload) {
    let index = state.rez_durumlari.findIndex((x) => x.k_no == payload.k_no);
    state.rez_durumlari[index] = payload;
  },
  SET_REZERVASYONDURUM_DELETE(state, k_no) {
    let altindex = state.rez_durumlari.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.rez_durumlari.splice(altindex, 1);
    }
    let index = state.rez_durumlari.findIndex((x) => x.k_no == k_no);
    state.rez_durumlari.splice(index, 1);
  },
};
const actions = {
  async rezervasyonDurumListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/rezervasyon-durum-listele', payload).then((res) => {
      commit('SET_REZERVASYONDURUM', res.data.data);
      return res;
    });
  },
  async rezervasyonDurumEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/rezervasyon-durum-ekle', data).then((res) => {
      commit('SET_REZERVASYONDURUM_PUSH', res.data.data);
      return res;
    });
  },
  async rezervasyonDurumGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/rezervasyon-durum-guncelle', data).then((res) => {
      commit('SET_REZERVASYONDURUM_UPDATE', res.data.data);
      return res;
    });
  },
  async rezervasyonDurumSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/rezervasyon-durum-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async rezervasyonDurumSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/rezervasyon-durum-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_REZERVASYONDURUM_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
