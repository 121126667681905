import axiosIns from '@/libs/axios';
const state = {
  mail_bildirim: [],
};
const getters = {
  getMailBildirim: (state) => state.mail_bildirim,
};
const mutations = {
  SET_MAIL_BILDIRIM(state, payload) {
    state.mail_bildirim = payload;
  },
};
const actions = {
  async mailBildirimGetir({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/mail-bildirim-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_MAIL_BILDIRIM', res.data.data);
      } else {
        commit('SET_MAIL_BILDIRIM', data);
      }
      return res;
    });
  },
  async mailBildirimEkle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/ayarlar/mail-bildirim-ekle', payload).then((res) => {
      commit('SET_MAIL_BILDIRIM', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
