import axiosIns from '@/libs/axios';
const state = {
  banner: [],
};
const getters = {
  getBanner: (state) => {
    return state.banner;
  },
};
const mutations = {
  SET_BANNER(state, payload) {
    state.banner = payload;
  },
  SET_BANNER_PUSH(state, payload) {
    state.banner.push(payload);
  },
  SET_BANNER_UPDATE(state, payload) {
    let index = state.banner.findIndex((x) => x.k_no == payload.k_no);
    state.banner[index] = payload;
  },
  SET_BANNER_DELETE(state, k_no) {
    let index = state.banner.findIndex((x) => x.k_no == k_no);
    state.banner.splice(index, 1);
  },
};
const actions = {
  async bannerListele({ commit }, data) {
    return await axiosIns.post('/magaza/banner-listele', data).then((res) => {
      commit('SET_BANNER', res.data.data);
      return res;
    });
  },
  async bannerEkle({ commit }, data) {
    return await axiosIns
      .post('/magaza/banner-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          const data = res.data.data;
          data.forEach((element) => {
            commit('SET_BANNER_PUSH', element);
          });
          return res;
        }
      });
  },
  async bannerGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/banner-guncelle', payload).then((res) => {
      commit('SET_BANNER_UPDATE', res.data.data);
      return res;
    });
  },

  async bannerSiraGuncelle({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/magaza/banner-sira-guncelle', payload).then((res) => {
      commit('SET_BANNER', res.data.data);
      return res;
    });
  },
  async bannerSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/magaza/banner-sil', payload).then((res) => {
      commit('SET_BANNER_DELETE', k_no);
      return res;
    });
  },
  async bannerFindOne({}, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/magaza/banner-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
