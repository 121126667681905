import axiosIns from '@/libs/axios';
const state = {
  stok_risk: [],
};
const getters = {
  getStokRiskRaporu: (state) => state.stok_risk,
};
const mutations = {
  SET_STOK_RISK(state, payload) {
    state.stok_risk = payload;
  },
  SET_STOK_RISK_PUSH(state, payload) {
    state.stok_risk.push(payload);
  },
};
const actions = {
  async urunStokRiskRaporuListele({ commit }, data) {
    return await axiosIns.post('/raporlar/urun-stok-risk-raporu-listele', data).then((res) => {
      commit('SET_STOK_RISK', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
