import axiosIns from '@/libs/axios';
const state = {
  uyeler: [],
  kuponlar: [],
};
const getters = {
  getUyeler: (state) => state.uyeler,
  getUyeKuponlar: (state) => state.kuponlar,
};
const mutations = {
  SET_UYELER(state, payload) {
    state.uyeler = payload;
  },
  SET_UYELER_PUSH(state, payload) {
    state.uyeler.push(payload);
  },
  SET_UYELER_UPDATE(state, payload) {
    let index = state.uyeler.findIndex((x) => x.k_no == payload.k_no);
    state.uyeler[index] = payload;
  },
  SET_UYELER_DELETE(state, k_no) {
    let index = state.uyeler.findIndex((x) => x.k_no == k_no);
    state.uyeler.splice(index, 1);
  },

  SET_UYE_KUPONLAR(state, payload) {
    state.kuponlar = payload;
  },
  SET_UYE_KUPONLAR_PUSH(state, payload) {
    state.kuponlar.push(payload);
  },
  SET_UYE_KUPONLAR_UPDATE(state, payload) {
    let index = state.kuponlar.findIndex((x) => x.k_no == payload.k_no);
    state.kuponlar[index] = payload;
  },

  SET_UYE_KUPONLAR_DELETE(state, k_no) {
    let index = state.kuponlar.findIndex((x) => x.k_no == k_no);
    state.kuponlar.splice(index, 1);
  },
};
const actions = {
  async uyeListele({ commit }, data) {
    return await axiosIns.post('/uyeler/uye-listele', data).then((res) => {
      commit('SET_UYELER', res.data.data);
      return res;
    });
  },

  async uyeFindOne({}, k_no) {
    return await axiosIns.post('/uyeler/uye-getir', { k_no }).then((res) => {
      if (res.data.success == true) {
        return res.data.data;
      }
    });
  },

  async uyeEkle({ commit }, payload) {
    return await axiosIns
      .post('/uyeler/uye-ekle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          commit('SET_UYELER_PUSH', res.data.data);
        }
        return res;
      });
  },

  async uyeGuncelle({ commit }, payload) {
    return await axiosIns
      .post('/uyeler/uye-guncelle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_UYELER_UPDATE', res.data.data);
        return res;
      });
  },

  async uyeSil({ commit }, k_no) {
    return await axiosIns.post('/uyeler/uye-sil', { k_no }).then((res) => {
      commit('SET_UYELER_DELETE', k_no);
      return res;
    });
  },

  async uyeAdresEkle({ commit }, payload) {
    return await axiosIns.post('/uyeler/uye-adres-ekle', payload).then((res) => {
      if (res.data.success == true) {
        return res;
      }
    });
  },

  async uyeAdresGuncelle({ commit }, payload) {
    return await axiosIns.post('/uyeler/uye-adres-guncelle', payload).then((res) => {
      if (res.data.success == true) {
        return res;
      }
    });
  },

  async uyeAdresSil({ commit }, k_no) {
    return await axiosIns.post('/uyeler/uye-adres-sil', { k_no }).then((res) => {
      if (res.data.success == true) {
        return res;
      }
    });
  },

  async uyeKuponListele({ commit }, payload) {
    return await axiosIns.post('/uyeler/uye-kupon-listele', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_UYE_KUPONLAR', res.data.data);
        return res;
      }
    });
  },

  async uyeKuponEkle({ commit }, payload) {
    return await axiosIns.post('/uyeler/uye-kupon-ekle', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_UYE_KUPONLAR_PUSH', res.data.data);
        return res;
      }
    });
  },
  async uyeKuponGuncelle({ commit }, payload) {
    return await axiosIns.post('/uyeler/uye-kupon-guncelle', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_UYE_KUPONLAR_UPDATE', res.data.data);
        return res;
      }
    });
  },
  async uyeKuponSil({ commit }, k_no) {
    return await axiosIns.post('/uyeler/uye-kupon-sil', { k_no }).then((res) => {
      if (res.data.success == true) {
        commit('SET_UYE_KUPONLAR_DELETE', k_no);
        return res;
      }
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
