import axiosIns from '@/libs/axios';
const state = {
  firmalar: [],
  firma_hareketler: [],
  firma_balance: [],
};
const getters = {
  getFirmalar: (state) => state.firmalar,
  getFirmaHareketler: (state) => state.firma_hareketler,
  getFirmaBalance: (state) => state.firma_balance,
};
const mutations = {
  // FİRMALAR
  SET_FIRMALAR(state, payload) {
    state.firmalar = payload;
  },
  SET_FIRMALAR_PUSH(state, payload) {
    state.firmalar.push(payload);
  },
  SET_FIRMALAR_UPDATE(state, payload) {
    let index = state.firmalar.findIndex((x) => x.k_no == payload.k_no);
    state.firmalar[index] = payload;
  },
  SET_FIRMALAR_DELETE(state, k_no) {
    let index = state.firmalar.findIndex((x) => x.k_no == k_no);
    state.firmalar.splice(index, 1);
  },

  // firma Hareketler
  SET_FIRMA_HAREKETLER(state, payload) {
    state.firma_hareketler = payload;
  },
  SET_FIRMA_BALANCE(state, payload) {
    state.firma_balance = payload;
  },
  SET_FIRMA_HAREKETLER_PUSH(state, payload) {
    state.firma_hareketler.push(payload);
  },
  SET_FIRMA_HAREKETLER_UPDATE(state, payload) {
    let index = state.firma_hareketler.findIndex((x) => x.k_no == payload.k_no);
    state.firma_hareketler[index] = payload;
  },
  SET_FIRMA_HAREKETLER_DELETE(state, k_no) {
    let index = state.firma_hareketler.findIndex((x) => x.k_no == k_no);
    state.firma_hareketler.splice(index, 1);
  },
};
const actions = {
  // firmalar
  async firmaListele({ commit }, k_no) {
    return await axiosIns.post('/yonetim/firma-listele', { k_no }).then((res) => {
      if (res.data.success == true) {
        commit('SET_FIRMALAR', res.data.data);
      }
    });
  },
  async firmalar({ commit }, firma_k_no) {
    return await axiosIns.post('/yonetim/firmalar', { firma_k_no }).then((res) => {
      if (res.data.success == true) {
        return res.data.data;
      }
    });
  },
  async firmafindOne({}, k_no) {
    return await axiosIns.post('/yonetim/firma-getir', { k_no }).then((res) => {
      if (res.data.success == true) {
        return res.data.data;
      }
    });
  },
  async firmaEkle({ commit }, payload) {
    return await axiosIns
      .post('/yonetim/firma-ekle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_FIRMALAR_PUSH', res.data.data[0]);
        return res;
      });
  },
  async firmaGuncelle({ commit }, payload) {
    return await axiosIns
      .post('/yonetim/firma-guncelle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_FIRMALAR_UPDATE', res.data.firma[0]);
        return res;
      });
  },
  async firmaSil({ commit }, k_no) {
    return await axiosIns.post('/yonetim/firma-sil', { k_no }).then((res) => {
      commit('SET_FIRMALAR_DELETE', k_no);
      return res;
    });
  },

  // firma Hareketler
  async firmaHareketListele({ commit }, payload) {
    return await axiosIns.post('/yonetim/firma-hareket-listele', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_FIRMA_BALANCE', res.data.balance);
        commit('SET_FIRMA_HAREKETLER', res.data.data);
        return res.data;
      }
    });
  },
  async firmaHareketEkle({ commit }, payload) {
    return await axiosIns.post('/yonetim/firma-hareket-ekle', payload).then((res) => {
      commit('SET_FIRMA_BALANCE', res.data.balance);
      commit('SET_FIRMA_HAREKETLER_PUSH', res.data.data);
      return res;
    });
  },
  async firmaHareketGuncelle({ commit }, payload) {
    return await axiosIns.post('/yonetim/firma-hareket-guncelle', payload).then((res) => {
      commit('SET_FIRMA_BALANCE', res.data.balance);
      commit('SET_FIRMA_HAREKETLER_UPDATE', res.data.data);
      return res;
    });
  },
  async firmaHareketSil({ commit }, k_no) {
    return await axiosIns.post('/yonetim/firma-hareket-sil', { k_no }).then((res) => {
      commit('SET_FIRMA_HAREKETLER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
