import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  destekler: [],
};
const getters = {
  getDestekHatti: (state) => state.destekler,
};
const mutations = {
  SET_DESTEKHATTI(state, payload) {
    state.destekler = payload;
  },
  SET_DESTEKHATTI_PUSH(state, payload) {
    state.destekler.push(payload);
  },
  SET_DESTEKHATTI_UPDATE(state, payload) {
    let index = state.destekler.findIndex((x) => x.k_no == payload.k_no);
    state.destekler[index] = payload;
  },
  SET_DESTEKHATTI_DELETE(state, k_no) {
    let index = state.destekler.findIndex((x) => x.k_no == k_no);
    state.destekler.splice(index, 1);
  },
};
const actions = {
  async destekHattiListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/destek/destek-hatti-listele', payload).then((res) => {
      commit('SET_DESTEKHATTI', res.data.data);
      return res;
    });
  },
  async destekHattiEkle({ commit }, data) {
    return await axiosIns.post('/destek/destek-hatti-ekle', data).then((res) => {
      commit('SET_DESTEKHATTI_PUSH', res.data.data);
      return res;
    });
  },

  async destekHattiCevapEkle({ commit }, data) {
    return await axiosIns.post('/destek/destek-hatti-cevap-ekle', data).then((res) => {
      return res;
    });
  },

  async destekHattiGuncelle({ commit }, data) {
    return await axiosIns.post('/destek/destek-hatti-guncelle', data).then((res) => {
      commit('SET_DESTEKHATTI_UPDATE', res.data.data);
      return res;
    });
  },
  async destekHattiSil({ commit }, k_no) {
    return await axiosIns.post('/destek/destek-hatti-sil', { k_no }).then((res) => {
      return res;
    });
  },

  async destekHattiFindOne({ }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.get('/destek/destek-hatti-find-one', { params: payload }).then((res) => {
      if (res.data.success === true) return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
