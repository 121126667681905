import axiosIns from '@/libs/axios';

const state = {
  menuler: [],
  acente_menuler: [],
};
const getters = {
  getMenuler: (state) => state.menuler,
  getAcenteMenuler: (state) => state.acente_menuler,
};
const mutations = {
  SET_MENU(state, payload) {
    state.menuler = payload;
  },
  SET_MENU_PUSH(state, payload) {
    state.menuler.push(payload);
  },
  SET_MENU_UPDATE(state, payload) {
    let index = state.menuler.findIndex((x) => x.k_no == payload.k_no);
    state.menuler[index] = payload;
  },
  SET_MENU_DELETE(state, k_no) {
    let index = state.menuler.findIndex((x) => x.k_no == k_no);
    state.menuler.splice(index, 1);
  },

  SET_ACENTE_MENU(state, payload) {
    state.acente_menuler = payload;
  },
  SET_ACENTE_MENU_PUSH(state, payload) {
    state.acente_menuler.push(payload);
  },
  SET_ACENTE_MENU_UPDATE(state, payload) {
    let index = state.acente_menuler.findIndex((x) => x.k_no == payload.k_no);
    state.acente_menuler[index] = payload;
  },
  SET_ACENTE_MENU_DELETE(state, k_no) {
    let index = state.acente_menuler.findIndex((x) => x.k_no == k_no);
    state.acente_menuler.splice(index, 1);
  },
};
const actions = {
  async menuListele({ commit }) {
    return await axiosIns.post('/ayarlar/menu-listele').then((res) => {
      if (res.data.success == true) {
        commit('SET_MENU', res.data.data);
      }
    });
  },
  async menuEkle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/menu-ekle', payload).then((res) => {
      commit('SET_MENU_PUSH', res.data.data);
      return res;
    });
  },
  async menuGuncelle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/menu-guncelle', payload).then((res) => {
      commit('SET_MENU_UPDATE', res.data.data);
      return res;
    });
  },
  async menuSiraGuncelle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/menu-sira-guncelle', payload).then((res) => {
      commit('SET_MENU', res.data.data);
      return res;
    });
  },
  async menuSil({ commit }, k_no) {
    return await axiosIns.post('/ayarlar/menu-sil', { k_no }).then((res) => {
      commit('SET_MENU_DELETE', k_no);
      return res;
    });
  },

  //Acente Menuler
  async menuAcenteListele({ commit }) {
    return await axiosIns.post('/ayarlar/menu-acente-listele').then((res) => {
      if (res.data.success == true) {
        commit('SET_ACENTE_MENU', res.data.data);
      }
    });
  },
  async menuAcenteEkle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/menu-acente-ekle', payload).then((res) => {
      commit('SET_ACENTE_MENU_PUSH', res.data.data);
      return res;
    });
  },
  async menuAcenteGuncelle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/menu-acente-guncelle', payload).then((res) => {
      commit('SET_ACENTE_MENU_UPDATE', res.data.data);
      return res;
    });
  },
  async menuAcenteSiraGuncelle({ commit }, payload) {
    return await axiosIns.post('/ayarlar/menu-acente-sira-guncelle', payload).then((res) => {
      commit('SET_ACENTE_MENU', res.data.data);
      return res;
    });
  },
  async menuAcenteSil({ commit }, k_no) {
    return await axiosIns.post('/ayarlar/menu-acente-sil', { k_no }).then((res) => {
      commit('SET_ACENTE_MENU_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
