import axiosIns from '@/libs/axios';
const state = {
  urun_haber_ver: [],
};
const getters = {
  getUrunHaberVerRapor: (state) => state.urun_haber_ver,
};
const mutations = {
  SET_UYELER(state, payload) {
    state.urun_haber_ver = payload;
  },
  SET_UYELER_PUSH(state, payload) {
    state.urun_haber_ver.push(payload);
  },
  SET_UYELER_UPDATE(state, payload) {
    let index = state.urun_haber_ver.findIndex((x) => x.k_no == payload.k_no);
    state.urun_haber_ver[index] = payload;
  },
  SET_UYELER_DELETE(state, k_no) {
    let index = state.urun_haber_ver.findIndex((x) => x.k_no == k_no);
    state.urun_haber_ver.splice(index, 1);
  },
};
const actions = {
  async urunHaberVerListele({ commit }, data) {
    return await axiosIns.post('/raporlar/urun-haber-ver-listele', data).then((res) => {
      commit('SET_UYELER', res.data.data);
      return res;
    });
  },

  async urunHaberVerRaporSil({ commit }, k_no) {
    return await axiosIns.post('/raporlar/urun-haber-ver-sil', { k_no }).then((res) => {
      commit('SET_UYELER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
