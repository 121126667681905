import Vue from 'vue';
import moment from 'moment';
import store from '@/store';

Vue.filter('cur', (value) => {
  return Number(value).toFixed(2);
});

Vue.filter('exc', (value, sabit_kur) => {
  if (value > 0) {
    const kurlar = store.getters.getKurListesi;
    const secilen_kur = store.getters.getExchange;

    if (value != 0 && sabit_kur != undefined) {
      const sabit_kur_fiyat = kurlar.find((x) => x.banka_kodu === sabit_kur).satis;

      const secilen_kur_fiyat = secilen_kur == 'TRY' ? 1 : kurlar.find((x) => x.banka_kodu === secilen_kur.banka_kodu).satis;
      let fiyat = (parseFloat(value) * parseFloat(sabit_kur_fiyat)) / parseFloat(secilen_kur_fiyat);

      return parseFloat(fiyat).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  } else {
    return 0;
  }
});

Vue.filter('moment', (value) => {
  return moment(value).format('DD.MM.YYYY');
});

Vue.filter('momentTime', (value) => {
  return moment(value).format('HH:mm');
});

Vue.filter('momentFull', (value) => {
  return moment(value).format('DD.MM.YYYY HH:mm');
});

Vue.filter('momentHour', (value) => {
  return moment(value).format('HH:mm');
});

Vue.filter('momentText', (value) => {
  const defaultDil = store.getters.getDilAyarlari.varsayilan;
  return moment(value).locale(defaultDil).format('LLL');
});

Vue.filter('slug', (value) => {
  return value
    .toUpperCase()
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '');
});

Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});

Vue.filter('mask', (value, size = 2) => {
  let result = '';
  let parcala = value.split(' ');
  parcala.forEach((element) => {
    result += element.substr(0, size) + element.substr(2, element.length - size).replace(/./g, '*') + ' ';
  });

  return result;
});

Vue.filter('urunTipi', (value) => {
  return value == 'villa'
    ? 'Villa'
    : value == 'yat'
      ? 'Yat'
      : value == 'tur'
        ? 'Tur'
        : value == 'transfer'
          ? 'Transfer'
          : value == 'arac_kiralama'
            ? 'Araç Kiralama'
            : '';
});

Vue.filter('odemeYontemi', (value) => {
  return value == 'kredi_karti' ? 'Kredi Kartı' : value == 'havale_eft' ? 'Havale EFT' : 'Kapıda Ödeme';
});

Vue.filter('odemeTipi', (value) => {
  return value == 'on_odeme' ? 'Ön Ödemeli' : 'Hepsi';
});

Vue.filter('tahsilatDurum', (value) => {
  return value == 0
    ? 'Tahsil Edilmedi'
    : value == 1
      ? 'Ön Ödeme Tahsil Edildi'
      : value == 2
        ? 'Hepsi Tahsil Edildi'
        : value == 3
          ? 'Kısmı Tahsil Edildi'
          : 'Beklemede';
});

Vue.filter('rezKapama', (value) => {
  return value == 'aktif' ? 'Rezervasyon Aktif' : value == 'kapandi' ? 'Rezervasyon Kapandı' : 'Rezervasyon İptal Edildi';
});

Vue.filter('productImage', (gorseller, k_no) => {
  const base_url =
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
      : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

  const db = store.getters.getUserFirma.db;
  if (Object.keys(gorseller).length > 0) {
    const selectImg = gorseller.find((x) => x.kapak == true);
    if (selectImg != undefined) {
      return `${base_url}uploads/${db}/urun/${k_no}/${selectImg.gorsel}`;
    } else {
      return `${base_url}uploads/${db}/urun/${k_no}/${gorseller[0].gorsel}`;
    }
  } else {
    return `${base_url}uploads/no/no-image.jpg`;
  }
});

Vue.filter('transferImage', (gorseller, k_no) => {
  const base_url =
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
      : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

  const db = store.getters.getUserFirma.db;
  if (Object.keys(gorseller).length > 0) {
    const selectImg = gorseller.find((x) => x.kapak == true);
    if (selectImg != undefined) {
      return `${base_url}uploads/${db}/arac/${selectImg.gorsel}`;
    } else {
      return `${base_url}uploads/${db}/arac/${gorseller[0].gorsel}`;
    }
  } else {
    return `${base_url}uploads/no/no-image.jpg`;
  }
});

Vue.filter('sepetImage', (gorseller, k_no = null, type) => {
  const base_url =
    process.env.NODE_ENV === 'development'
      ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
      : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

  const db = store.getters.getUserFirma.db;

  if (Object.keys(gorseller).length > 0) {
    if (type == 'urun') {
      return `${base_url}uploads/${db}/urun/${k_no}/${gorseller.gorsel}`;
    } else {
      return `${base_url}uploads/${db}/arac/${gorseller.gorsel}`;
    }
  } else {
    return `${base_url}uploads/no/no-image.jpg`;
  }
});
