import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  transfer_tarifeleri: [],
};
const getters = {
  getTransferTarifeleri: (state) => state.transfer_tarifeleri,
};
const mutations = {
  SET_TTARIFELER(state, payload) {
    state.transfer_tarifeleri = payload;
  },
  SET_TTARIFELER_PUSH(state, payload) {
    state.transfer_tarifeleri.push(payload);
  },
  SET_TTARIFELER_UPDATE(state, payload) {
    let index = state.transfer_tarifeleri.findIndex((x) => x.k_no == payload.k_no);
    state.transfer_tarifeleri[index] = payload;
  },
  SET_TTARIFELER_DELETE(state, k_no) {
    let index = state.transfer_tarifeleri.findIndex((x) => x.k_no == k_no);
    state.transfer_tarifeleri.splice(index, 1);
  },
};
const actions = {
  async transferTarifeListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/urunler/transfer-tarife-listele', payload).then((res) => {
      commit('SET_TTARIFELER', res.data);
      return res;
    });
  },
  async transferTarifeEkle({ commit }, data) {
    return await axiosIns.post('/urunler/transfer-tarife-ekle', data).then((res) => {
      // commit('SET_TTARIFELER_PUSH', res.data.data);
      return res;
    });
  },
  async transferTarifeGuncelle({ commit }, data) {
    return await axiosIns.post('/urunler/transfer-tarife-guncelle', data).then((res) => {
      commit('SET_TTARIFELER_UPDATE', res.data.data);
      return res;
    });
  },
  async transferTarifeSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/urunler/transfer-tarife-sil', payload).then((res) => {
      commit('SET_TTARIFELER_DELETE', k_no);
      return res;
    });
  },
  async transferTarifeTopluSil({ commit }, payload) {
    return await axiosIns.post('/urunler/transfer-tarife-toplu-sil', payload).then((res) => {
      if (res.data.success == true) {
        payload.k_no.forEach((element) => {
          commit('SET_TTARIFELER_DELETE', element.k_no);
        });
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
