import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

import auth from './routes/auth';
import dashboard from './routes/dashboard';
import magaza from './routes/magaza';
import urunler from './routes/urunler';
import rezervasyonlar from './routes/rezervasyonlar';
import acenteler from './routes/acenteler';
import tanimlamalar from './routes/tanimlamalar';
import destek from './routes/destek';
import raporlar from './routes/raporlar';
import kullanicilar from './routes/kullanicilar';
import ayarlar from './routes/ayarlar';
import yonetim from './routes/yonetim';

import { isUserLoggedIn } from '@/libs/auth/isUserLogin';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...auth,
    ...dashboard,
    ...magaza,
    ...urunler,
    ...rezervasyonlar,
    ...acenteler,
    ...tanimlamalar,
    ...destek,
    ...raporlar,
    ...kullanicilar,
    ...ayarlar,
    ...yonetim,
  ],
});

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  let item = {};
  item.route = to.name;

  // store.commit('SET_URL', to.name);

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'login' });
  }

  // Yetkisi yoksa yonlendirme
  // if (isLoggedIn && to.name != 'misc-not-authorized') {
  //   next({ name: 'misc-not-authorized' });
  // }

  return next();
});

export default router;
