import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  urun_ozel_alanlar: [],
};
const getters = {
  getUrunOzelAlanlar: (state) => state.urun_ozel_alanlar,
};
const mutations = {
  SET_URUNOZELALAN(state, payload) {
    state.urun_ozel_alanlar = payload;
  },
  SET_URUNOZELALAN_PUSH(state, payload) {
    state.urun_ozel_alanlar.push(payload);
  },
  SET_URUNOZELALAN_UPDATE(state, payload) {
    let index = state.urun_ozel_alanlar.findIndex((x) => x.k_no == payload.k_no);
    state.urun_ozel_alanlar[index] = payload;
  },
  SET_URUNOZELALAN_DELETE(state, k_no) {
    let altindex = state.urun_ozel_alanlar.findIndex((x) => x.kategori_k_no == k_no);
    if (altindex != -1) {
      state.urun_ozel_alanlar.splice(altindex, 1);
    }
    let index = state.urun_ozel_alanlar.findIndex((x) => x.k_no == k_no);
    state.urun_ozel_alanlar.splice(index, 1);
  },
};
const actions = {
  async urunOzelAlanListele({ commit }, data) {
    const payload = {
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/urun-ozel-alan-listele', payload).then((res) => {
      commit('SET_URUNOZELALAN', res.data.data);
      return res;
    });
  },
  async urunOzelAlanEkle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-ozel-alan-ekle', data).then((res) => {
      commit('SET_URUNOZELALAN_PUSH', res.data.data);
      return res;
    });
  },
  async urunOzelAlanGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-ozel-alan-guncelle', data).then((res) => {
      commit('SET_URUNOZELALAN_UPDATE', res.data.data);
      return res;
    });
  },
  async urunOzelAlanSiraGuncelle({ commit }, data) {
    return await axiosIns.post('/tanimlamalar/urun-ozel-alan-sira-guncelle', data).then((res) => {
      return res;
    });
  },
  async urunOzelAlanSil({ commit }, k_no) {
    const payload = {
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/urun-ozel-alan-sil', payload).then((res) => {
      if (res.data.success) {
        commit('SET_URUNOZELALAN_DELETE', k_no);
      }
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
