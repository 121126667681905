import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  tema_ayar: [],
};
const getters = {
  getTemaAyar: (state) => state.tema_ayar,
};
const mutations = {
  SET_TEMA_AYAR(state, payload) {
    state.tema_ayar = payload;
  },
  SET_TEMA_AYAR_DELETE(state, k_no) {
    let index = state.tema_ayar.findIndex((x) => x.k_no == k_no);
    state.tema_ayar.splice(index, 1);
  },
};
const actions = {
  async temaAyarGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/tema-ayar-getir', payload).then((res) => {
      commit('SET_TEMA_AYAR', res.data.data);
      return res;
    });
  },
  async temaAyarEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/tema-ayar-ekle', payload).then((res) => {
      commit('SET_TEMA_AYAR', res.data.data);
      return res;
    });
  },
  async temaAyarSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/tema-ayar-sil', payload).then((res) => {
      commit('SET_TEMA_AYAR_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
